import React, { useEffect, useState } from 'react';
import Sidebar from '../../components/SideBar/Sidebar';
import * as Body from './Styles';
import * as defines from '../../../utils/defines/Defines';
import * as Functions from '../../../utils/functions/Functions';
import Button from '../../components/Buttons/Button/Button';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../components/Loading/Loading';
import { State } from '../../redux/store';
import DHMIcon from '../../../assets/icons/dhm-icon.svg';
import IndustryIcon from '../../../assets/icons/industry-icon.png';
import ChartIcon from '../../../assets/icons/chart-icon.png';
import DesktopOnly from './Modals/DesktopOnly';

type AppsProps = {
	api: string;
	cargo: number;
};

const ConnectedApps = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const userType = useSelector((state: State) => state.userType.userType);
	const selectedUnity = useSelector(
		(state: State) => state.selectedUnity.selectedUnity
	);

	const [loading, toggleLoading] = useState(true);
	const [apps, setApps] = useState<AppsProps[]>([]);
	const [showDesktopOnly, toggleDesktopOnly] = useState(false);

	useEffect(() => {
		toggleLoading(true);
		axios
			.get(
				`${defines.apiURL}/api/UsuarioCRUD?registeredApps=1&idFilial=${Functions.ReplaceSpecialCharacters(selectedUnity)}&userTypeRequest=${userType}`,
				{ withCredentials: true }
			)
			.then((response) => {
				setApps(response.data.content);
				toggleLoading(false);
			})
			.catch((error) => {
				Functions.ResolveErrors(
					error.response.status,
					error.response.data.code,
					error.response.data.message,
					navigate,
					dispatch,
					error.response.data.content
				);
				toggleLoading(false);
			});
	}, [dispatch, navigate, selectedUnity, userType]);

	const openApp = (link: string) => {
		window.open(link, '_blank');
	};

	if (loading) {
		return <Loading />;
	}

	return (
		<Body.Container>
			<Sidebar
				type={userType}
				content={defines.ContentSidebar.CONNECTED_APPS}
				tituloPagina="Aplicativos"
			/>
			<Body.Row style={{ width: '100%', justifyContent: 'center' }}>
				<Body.PageTitle></Body.PageTitle>
			</Body.Row>
			<Body.Row style={{ width: '100%', justifyContent: 'center' }}>
				<Body.SubTitle>
					Aqui estão as aplicações disponíveis.
				</Body.SubTitle>
			</Body.Row>

			<Body.Scroll>
				{apps.length === 1 && apps.some((app) => app.api === 'SSO') && (
					<Body.Row
						style={{
							width: '100%',
							justifyContent: 'center',
							marginTop: '20px',
						}}
					>
						<Body.Message>
							Nenhuma aplicação disponível no momento.
						</Body.Message>
					</Body.Row>
				)}

				{apps.some((app) => app.api === 'DHM') && (
					<Body.Card>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								marginBottom: '10px',
							}}
						>
							<Body.Icon src={DHMIcon} />
							<Body.Column style={{ marginLeft: '10px' }}>
								<Body.Title style={{ textAlign: 'left' }}>
									DHM4.0
								</Body.Title>
								<Body.SubTitleCard
									style={{ textAlign: 'left' }}
								>
									Monitore e personalize o sistema DHM4.0
									operando em sua usina
								</Body.SubTitleCard>
							</Body.Column>
						</div>
						<Button
							text="Abrir Aplicativo"
							action={() =>
								openApp(
									'https://www.dhm.dinamoautomacao.com.br'
								)
							}
						/>
						<>
							<Button
								color="yellow"
								text="Gerenciar Planos"
								action={() => toggleDesktopOnly(true)}
							/>
							<Button
								color="green"
								text="Gerenciar Empresas"
								action={() =>
									navigate('/apps-conectados/assinaturas', {
										state: { app: 'DHM' },
									})
								}
							/>
						</>
					</Body.Card>
				)}

				{apps.some((app) => app.api === 'INS') && (
					<Body.Card>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								marginBottom: '10px',
							}}
						>
							<Body.Icon src={IndustryIcon} />
							<Body.Column style={{ marginLeft: '10px' }}>
								<Body.Title style={{ textAlign: 'left' }}>
									IndustrySight
								</Body.Title>
								<Body.SubTitleCard
									style={{ textAlign: 'left' }}
								>
									Veja a operação da sua indústria em tempo
									real
								</Body.SubTitleCard>
							</Body.Column>
						</div>
						<Button
							text="Abrir Aplicativo"
							action={() => openApp('')}
						/>
						<>
							<Button
								color="yellow"
								text="Gerenciar Planos"
								action={() => toggleDesktopOnly(true)}
							/>
							<Button
								color="green"
								text="Gerenciar Empresas"
								action={() =>
									navigate('/apps-conectados/assinaturas', {
										state: { app: 'INS' },
									})
								}
							/>
						</>
					</Body.Card>
				)}
			</Body.Scroll>

			{showDesktopOnly && <DesktopOnly toggle={toggleDesktopOnly} />}
		</Body.Container>
	);
};

export default ConnectedApps;
