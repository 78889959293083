import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Input from '../../../components/Inputs/Input/Input';
import Modal from '../../../components/Modals/Modal/Modal';
import * as Body from '../Styles';
import Button from '../../../components/Buttons/Button/Button';
import ButtonWrapper from '../../../components/Buttons/ButtonWrapper/ButtonWrapper';
import AdministradorIcon from '../../../../assets/icons/adm-icon.svg';
import EngenheiroIcon from '../../../../assets/icons/usuario-icon.svg';
import * as Functions from '../../../../utils/functions/Functions';
import * as defines from '../../../../utils/defines/Defines';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '../../../../assets/icons/Search.svg';
import DatetimeInput from '../../../components/DatetimeInput/DatetimeInput';
import { State } from '../../../redux/store';

const TipoUsuario = {
	Tecnico: 0,
	Suporte: 1,
};

type FilialProps = {
	idFilial: string;
	nomeFilial: string;
	cnpjFilial: string;
	statusAssinatura: number;
	idEmpresa: string;
	cnpjEmpresa: string;
	nomeEmpresa: string;
};

type UsuarioProps = {
	idUsuario: string;
	nomeCompleto: string;
	email: string;
};

type SelectUnitProps = {
	toggle: Dispatch<SetStateAction<boolean>>;
	filial: FilialProps;
	app: string;
};

const InviteVisiting: React.FC<SelectUnitProps> = ({ toggle, filial, app }) => {
	const userType = useSelector((state: State) => state.userType.userType);

	const [loading, toggleLoading] = useState(false);
	const [email, setEmail] = useState('');
	const [validade, setValidade] = useState(
		new Date().toISOString().split('T')[0]
	);
	const [errorEmail, toggleErrorEmail] = useState(false);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [selectedRole, setSelectedRole] = useState(0);

	useEffect(() => {}, [navigate, dispatch]);

	const handleSelectRole = (role: number) => {
		setSelectedRole(role); // Atualiza o tipo de usuário selecionado
	};

	const invite = () => {
		if (!Functions.verifyEmail(email)) {
			toggleErrorEmail(true);
			return;
		}
		toggleLoading(true);
		const payload = {
			validade: validade,
			email: email,
			permissaoEscrita: selectedRole,
			api: app === 'INS' ? 'INS' : 'DHM',
		};

		axios
			.post(
				defines.apiURL +
					'/api/UsuarioVisitanteAPI' +
					'?userTypeRequest=' +
					userType +
					'&idFilial=' +
					Functions.ReplaceSpecialCharacters(filial.idFilial),
				payload,
				{ withCredentials: true }
			)
			.then((response) => {
				toggleLoading(false);
				toggle(false);
				dispatch({
					type: 'ADD_NOTIFICATION',
					status: 1,
					title: '',
					text: 'Convite enviado com sucesso!',
				});
			})
			.catch((error) => {
				toggleLoading(false);
				Functions.ResolveErrors(
					error.response.status,
					error.response.data.code,
					error.response.data.message,
					navigate,
					dispatch,
					error.response.data.content
				);
			});
	};

	const getAplicativo = (app: string) => {
		switch (app) {
			case 'DHM':
				return 'DHM4.0';
			case 'REL':
				return 'Relatórios';
			case 'INS':
				return 'IndustrySight';
			default:
				navigate('/permissao');
				return '';
		}
	};

	return (
		<Modal
			title={'Convidar Usuário Visitante para o ' + getAplicativo(app)}
			toggleModal={toggle}
			loading={loading}
		>
			<div
				style={{
					width: '600px',
					display: 'flex',
					flexDirection: 'column',
					gridGap: '15px 0px',
				}}
			>
				<Body.Row>
					<Input
						title="Email"
						text={email}
						setText={setEmail}
						placeholder="Email"
						error={errorEmail}
						width={370}
					/>
					<DatetimeInput
						title="Conceder acesso até"
						text={validade}
						setText={setValidade}
						removeHours
					/>
				</Body.Row>
				<Body.Row>
					{errorEmail ? (
						<Body.TextError>
							Formato de email inválido ex: email@email.com
						</Body.TextError>
					) : null}
				</Body.Row>

				<ButtonWrapper
					style={{ width: '100%' }}
					action={() => handleSelectRole(TipoUsuario.Tecnico)}
				>
					<Body.ModalCard
						style={{
							backgroundColor:
								selectedRole === TipoUsuario.Tecnico
									? '#ebebeb'
									: 'white',
						}}
					>
						<Body.Row>
							<Body.ModalCardIcon src={AdministradorIcon} />
							<Body.Column>
								<Body.ButtonTitle>
									Técnico Visitante
								</Body.ButtonTitle>
								<Body.Text>
									Apenas visualizar funcionamento do sistema.
								</Body.Text>
							</Body.Column>
						</Body.Row>
					</Body.ModalCard>
				</ButtonWrapper>
				<ButtonWrapper
					style={{ width: '100%' }}
					action={() => handleSelectRole(TipoUsuario.Suporte)}
				>
					<Body.ModalCard
						style={{
							backgroundColor:
								selectedRole === TipoUsuario.Suporte
									? '#ebebeb'
									: 'white',
						}}
					>
						<Body.Row>
							<Body.ModalCardIcon src={AdministradorIcon} />
							<Body.Column>
								<Body.ButtonTitle>
									Suporte Visitante
								</Body.ButtonTitle>
								<Body.Text>
									Visualizar funcionamento e adicionar
									intervenções.
								</Body.Text>
							</Body.Column>
						</Body.Row>
					</Body.ModalCard>
				</ButtonWrapper>

				<br />
				<Body.Row>
					<Button
						text="Convidar"
						action={() => {
							invite();
						}}
					/>
				</Body.Row>
			</div>
		</Modal>
	);
};

export default InviteVisiting;
