import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Modal from '../../../components/Modals/Modal/Modal';
import Input from '../../../components/Inputs/Input/Input';
import * as Body from '../Styles';
import * as Functions from '../../../../utils/functions/Functions';
import * as Defines from '../../../../utils/defines/Defines';
import Button from '../../../components/Buttons/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { State } from '../../../redux/store';

interface BasePlanoProps {
	idPlanoInSight?: string;
	idPlanosDHM?: string;
	idPlanosRelatorios?: string;
	nomePlano: string;
	preco: number;
	createdAt: string;
}

type PlanoProps = BasePlanoProps & {
	[key: string]: any;
	[key: number]: any;
};

type CreatePlanProps = {
	toggle: Dispatch<SetStateAction<boolean>>;
	idPlano: string;
	planos: PlanoProps[];
	regenerate: () => void;
	app: string;
};

export const CreatePlan: React.FC<CreatePlanProps> = ({
	toggle,
	idPlano,
	planos,
	regenerate,
	app,
}) => {
	const [loading, toggleLoading] = useState(false);
	const [nomePlano, setNomePlano] = useState('');
	const [quotaUsuarios, setQuotaUsuarios] = useState('');
	const [quotaAtuadorSensor, setQuotaAtuadorSensor] = useState('');
	const [quotaEnvio, setQuotaEnvio] = useState('');
	const [preco, setPreco] = useState('');
	const userType = useSelector((state: State) => state.userType.userType);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const getAplicativo = (app: string) => {
		switch (app) {
			case 'DHM':
				return 'DHM4.0';
			case 'REL':
				return 'Relatórios';
			case 'INS':
				return 'IndustrySight';
			default:
				navigate('/permissao');
				return '';
		}
	};

	const createPlan = () => {
		toggleLoading(true);
		const data: any = { nomePlano, preco: preco };
		if (quotaUsuarios !== '') data.quotaUsuarios = parseInt(quotaUsuarios);
		if (quotaAtuadorSensor !== '')
			data.quotaAtuadorSensor = parseInt(quotaAtuadorSensor);
		if (quotaEnvio !== '') data.quotaEnvio = parseInt(quotaEnvio);

		axios
			.post(
				Defines.apiURL +
					'/api/PlanosCRUD?userTypeRequest=' +
					userType +
					'&api=' +
					app,
				data,
				{ withCredentials: true }
			)
			.then((response) => {
				dispatch({
					type: 'ADD_NOTIFICATION',
					status: 1,
					title: '',
					text: 'Plano criado com sucesso',
				});
				regenerate();
				toggle(false);
				toggleLoading(false);
			})
			.catch((error) => {
				dispatch({
					type: 'ADD_NOTIFICATION',
					status: 3,
					title: 'Erro',
					text: error.response.data.message,
				});
				Functions.ResolveErrors(
					error.response.status,
					error.response.data.code,
					error.response.data.message,
					navigate,
					dispatch,
					error.response.data.content
				);
				toggleLoading(false);
			});
	};

	const insightPlan = () => {
		return (
			<>
				<Body.Row>
					<Input
						width={340}
						title="Nome do Plano"
						text={nomePlano}
						setText={setNomePlano}
					/>
					<Input
						width={140}
						title="Preço"
						text={preco}
						setText={setPreco}
					/>
				</Body.Row>
				<Body.Row>
					<Input
						width={120}
						title="Quota de Usuários"
						text={quotaUsuarios}
						setText={setQuotaUsuarios}
					/>
					<Input
						width={220}
						title="Quota de Sensores/ Atuadores"
						text={quotaAtuadorSensor}
						setText={setQuotaAtuadorSensor}
					/>
					<Input
						width={120}
						title="Quota de Envios"
						text={quotaEnvio}
						setText={setQuotaEnvio}
					/>
				</Body.Row>
			</>
		);
	};

	const dhmPlan = () => {
		return (
			<>
				<Body.Row>
					<Input
						width={340}
						title="Nome do Plano"
						text={nomePlano}
						setText={setNomePlano}
					/>
					<Input
						width={140}
						title="Preço"
						text={preco}
						setText={setPreco}
					/>
				</Body.Row>
				<Body.Row>
					<Input
						width={500}
						title="Quota de Usuários"
						text={quotaUsuarios}
						setText={setQuotaUsuarios}
					/>
				</Body.Row>
			</>
		);
	};

	return (
		<Modal
			title={'Criar Plano - ' + getAplicativo(app)}
			toggleModal={toggle}
		>
			<div
				style={{
					width: '500px',
					display: 'flex',
					flexDirection: 'column',
					gridGap: '15px 0px',
				}}
			>
				{app === 'INS' ? insightPlan() : dhmPlan()}
				<br />
				<Button
					text="Salvar"
					action={() => {
						createPlan();
					}}
					loading={loading}
				/>
			</div>
		</Modal>
	);
};

export default CreatePlan;
