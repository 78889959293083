import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Input from '../../../components/Inputs/Input/Input';
import Modal from '../../../components/Modals/Modal/Modal';
import * as Body from '../Styles';
import Button from '../../../components/Buttons/Button/Button';
import ButtonWrapper from '../../../components/Buttons/ButtonWrapper/ButtonWrapper';
import AdministradorIcon from '../../../../assets/icons/adm-icon.svg';
import EngenheiroIcon from '../../../../assets/icons/usuario-icon.svg';
import * as Functions from '../../../../utils/functions/Functions';
import * as defines from '../../../../utils/defines/Defines';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '../../../../assets/icons/Search.svg';
import DatetimeInput from '../../../components/DatetimeInput/DatetimeInput';
import { State } from '../../../redux/store';

type FilialProps = {
	idFilial: string;
	nomeFilial: string;
	cnpjFilial: string;
	statusAssinatura: number;
	idEmpresa: string;
	cnpjEmpresa: string;
	nomeEmpresa: string;
};

type UsuarioProps = {
	idUsuario: string;
	nomeCompleto: string;
	email: string;
	visitante?: number;
	cargo: number;
	idVisitante: string;
	idAPI: string;
	validade: string;
};

type SelectUnitProps = {
	toggle: Dispatch<SetStateAction<boolean>>;
	usuario: UsuarioProps;
	filial: FilialProps;
	regenerate: () => void;
	app: string;
};

const DeleteUser: React.FC<SelectUnitProps> = ({
	toggle,
	usuario,
	filial,
	regenerate,
	app,
}) => {
	const userType = useSelector((state: State) => state.userType.userType);

	const [loading, toggleLoading] = useState(false);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	useEffect(() => {}, [navigate, dispatch]);

	const handleDelete = () => {
		if (usuario.idAPI) {
			const apiEndpoint =
				app === 'INS' ? '/api/InSightCRUD' : '/api/DHMCrud';
			const idKey = app === 'INS' ? '&idInSight=' : '&id=';

			toggleLoading(true);
			axios
				.delete(
					defines.apiURL +
						apiEndpoint +
						'?userTypeRequest=' +
						userType +
						'&idFilial=' +
						Functions.ReplaceSpecialCharacters(filial.idFilial) +
						idKey +
						Functions.ReplaceSpecialCharacters(usuario.idAPI),
					{ withCredentials: true }
				)
				.then((response) => {
					toggleLoading(false);
					toggle(false);
					dispatch({
						type: 'ADD_NOTIFICATION',
						status: 1,
						title: '',
						text: 'Remoção feita com sucesso!',
					});
					regenerate();
				})
				.catch((error) => {
					toggleLoading(false);
					toggle(false);
					Functions.ResolveErrors(
						error.response.status,
						error.response.data.code,
						error.response.data.message,
						navigate,
						dispatch,
						error.response.data.content
					);
				});
		}

		if (usuario.idVisitante) {
			axios
				.delete(
					defines.apiURL +
						'/api/UsuarioVisitanteAPI' +
						'?userTypeRequest=' +
						userType +
						'&idFilial=' +
						Functions.ReplaceSpecialCharacters(filial.idFilial) +
						'&id=' +
						Functions.ReplaceSpecialCharacters(usuario.idVisitante),
					{ withCredentials: true }
				)
				.then((response) => {
					toggleLoading(false);
					toggle(false);
					dispatch({
						type: 'ADD_NOTIFICATION',
						status: 1,
						title: '',
						text: 'Remoção feita com sucesso!',
					});
					regenerate();
				})
				.catch((error) => {
					toggleLoading(false);
					Functions.ResolveErrors(
						error.response.status,
						error.response.data.code,
						error.response.data.message,
						navigate,
						dispatch,
						error.response.data.content
					);
				});
		}
	};

	const getAplicativo = (app: string) => {
		switch (app) {
			case 'DHM':
				return 'DHM4.0';
			case 'REL':
				return 'Relatórios';
			case 'INS':
				return 'IndustrySight';
			default:
				navigate('/permissao');
				return '';
		}
	};

	return (
		<Modal
			title={'Remover direitos de acesso - ' + getAplicativo(app)}
			toggleModal={toggle}
			loading={loading}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gridGap: '15px 0px',
				}}
			>
				<Body.Text>
					Você tem certeza que gostaria de remover os direitos de
					acesso de
					<span style={{ color: defines.BlueTec }}>
						&nbsp;{usuario.nomeCompleto}&nbsp;
					</span>
					da aplicação
					<span style={{ color: defines.BlueTec }}>
						&nbsp;{getAplicativo(app)}
					</span>
					?
				</Body.Text>
				<br />
				<Body.Row>
					<Button
						text="Cancelar"
						action={() => {
							toggle(false);
						}}
					/>
					<Button
						color="red"
						text="Remover"
						action={() => {
							handleDelete();
						}}
					/>
				</Body.Row>
			</div>
		</Modal>
	);
};

export default DeleteUser;
