import styled, { keyframes } from 'styled-components';
import * as defines from '../../../utils/defines/Defines';

export const Wrapper = styled.div`
    height: '100vh', // Altura de 100% da janela de visualização
    overflowY: 'auto', // Habilita o scroll vertical caso o conteúdo seja maior que a tela
	display: 'flex', // Exibe os elementos em linha
	flexDirection: 'column', // Alinha os elementos verticalmente
	alignItems: 'center', // Centraliza horizontalmente
	justifyContent: 'flex-start', // Alinha os elementos no topo

	& > *:not(:first-child) {
		padding-left: 20px;
		padding-right: 20px;
	}
`

export const Container = styled.div`
	background: ${defines.BackgroundColor};
	display: flex;
	flex-direction: column;
	height: 100vh; /* Altura da viewport para evitar compressão */
	align-items: center; /* Centraliza horizontalmente */
	justify-content: flex-start; /* Ajusta a posição vertical */
	padding: 20px;
	overflow-y: auto; /* Permite rolagem vertical */
	box-sizing: border-box;
`;

export const HeaderButton = styled.div`
	background-color: #1080d6;
	width: 30px;
	height: 30px;
	border-radius: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	:hover {
		transition: 0.3s;
		opacity: 0.8;
	}
`;

export const FindContent = styled.div`
	min-width: 30px;
	position: relative;
`;

export const Icon = styled.img`
	top: 7.5px;
	left: 7.5px;
	width: 15px;
	height: 15px;
	position: absolute;
	z-index: 3;
`;
export const Scroll = styled.div`
	width: 100vw;
	overflow-y: scroll;
	::-webkit-scrollbar {
		display: none;
	}
	flex-direction: column;
	box-sizing: border-box;
	justify-content: center;
	align-items: center;
	grid-gap: 20px 0px;
	padding: 20px;
`;

export const CardInput = styled.div`
	display: flex;
	grid-gap: 0px 10px;
	padding: 10px;
	box-sizing: border-box;
	background: rgba(0, 0, 0, 0.02);
	border-radius: 5px;
	cursor: pointer;
`;

export const Find = styled.input`
	width: 30px;
	height: 30px;
	max-width: 200px;
	border-radius: 15px;
	border: 1px solid #1080d6;
	padding-left: 30px;
	font-family: 'Nunito';
	transition: 0.5s;
	background: none;
	z-index: 5;
	:focus {
		transition: 0.5s;
		width: 300px;
	}
`;

export const Title = styled.h1`
	font-family: 'Nunito';
	font-weight: bold;
	font-size: 18px;
	color: #000;
`;

export const Row = styled.div`
	display: flex;
	grid-gap: 0px 10px;
	width: 100%;
	justify-content: center;
	min-height: 80px;
`;
export const SubTitle = styled.p`
	font-family: 'Nunito';
	font-size: 14px;
	color: #6d7989;
	margin-bottom: 5px;
`;
export const CardUnit = styled.div`
	width: 100%;
	height: 45px;
	background: #fff;
	border-radius: 12px;
	border: 1px solid rgba(200, 200, 200, 0.5);
	outline: none;
	transition: 0.35s;
	display: flex;
	align-items: center;
	padding-left: 15px;
	box-sizing: border-box;
	cursor: pointer;
`;

export const TextUnit = styled.p`
	font-family: 'Comfortaa';
	white-space: nowrap;
	overflow: hidden;
	text-overflow: clip;
	font-size: 13px;
`;

export const Text = styled.p`
	font-family: 'Nunito';
	font-size: 16px;
	color: rgba(0, 0, 0, 0.8);
`;
export const Card = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	background-color: #ffffff;
	border-radius: 20px;
	padding: 20px;
	box-sizing: border-box;
	grid-gap: 10px 0px;
	text-align: center;
`;
export const SelectUser = styled.div`
	grid-gap: 0px 10px;
	width: 100%;
	height: 60px;
	border-radius: 12px;
	border: 1px solid rgba(200, 200, 200, 0.5);
	cursor: pointer;
	margin-bottom: 10px;
	display: flex;
	align-items: center;
	padding: 20px;
	box-sizing: border-box;
	transition: 0.5s;
	:hover {
		transition: 0.5s;
		background: rgba(0, 0, 0, 0.02);
	}
`;

export const Photo = styled.img`
	width: 100%;
	max-width: 180px;
	min-width: 100px;
	height: auto;
	border-radius: 50%;
	aspect-ratio: 1 / 1;
`;

export const Column = styled.div`
	display: flex;
	flex-direction: column;
	grid-gap: 10px 0px;
	align-items: left;
`;

export const ButtonTitle = styled.a`
	font-family: 'Comfortaa';
	font-size: 16px;
	font-weight: bold;
	color: #000;
	max-width: 300px;
	word-wrap: break-word;
`;

export const ModalCardIcon = styled.img`
	margin-right: 15px;
	width: 47px;
	z-index: 3;
`;

const CardAnimate = keyframes`
    from{
        right: -10vw;
        opacity: 0;
    }
    to{
        right: 80px;
        opacity: 1;
    }
`;

export const ModalCard = styled.div`
	width: 100%;
	border-radius: 20px;
	padding: 10px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	box-sizing: border-box;
	grid-gap: 15px 0px;
	animation: 0.5s ${CardAnimate} ease-in;
	transition: 0.5s;
	background: white;

	&:hover {
		background: #ebebeb;
	}
`;
const TextAnimate = keyframes`
    from{
        margin-top: -10px;
        opacity: 0;
    }
    to{
        margin-top: 0px;
        opacity: 1;
    }
`;

export const TextError = styled.a`
	font-family: 'Comfortaa';
	font-size: 10px;
	color: ${defines.Red};
	animation: 0.35s ${TextAnimate} ease-in;
`;

export const ToggleBtn = styled.div<{ active: boolean }>`
	width: 30px;
	height: 30px;
	margin-right: 10px;
	border-radius: 6px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${(props) => (props.active ? defines.BlueTec : '#FFF')};
	border: 2px solid ${(props) => (props.active ? defines.BlueTec : 'gray')};
	transition: 0.15s;
`;
export const IconCheckbox = styled.img`
	width: 15px;
`;

export const NoteText = styled.a`
	font-family: 'Comfortaa';
	font-size: 12px;
	color: #6d7989;
`;

export const PageTitle = styled.a`
	text-align: center;
	font-family: 'Comfortaa';
	font-weight: bold;
	font-size: 24px;
	margin-bottom: 10px;
`;

export const CardIcon = styled.img`
	width: 50px;
	min-width: 50px;
	max-width: 50px;
`;

export const CardTitle = styled.a`
	font-family: 'Comfortaa';
	font-size: 12px;
	font-weight: bold;
	color: #000;
`;
