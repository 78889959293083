import React, { useEffect, useRef, useState } from 'react';
import Sidebar from '../../components/SideBar/Sidebar';
import * as Body from './Styles';
import SearchIcon from '../../../assets/icons/Search.svg';
import AddIcon from '../../../assets/icons/add-icon.svg';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../components/Loading/Loading';
import Avatar from '../../../assets/images/avatar.png';
import * as Functions from '../../../utils/functions/Functions';
import * as defines from '../../../utils/defines/Defines';
import { State } from '../../redux/store';
import createUser from '../../../webapp/pages/createUser/createUser';
import * as Types from '../../../utils/types/Types';
import ButtonWrapper from '../../components/Buttons/ButtonWrapper/ButtonWrapper';
import ArrowBack from '../../../assets/icons/arrow-back-preto-icon.svg';
import { get } from 'http';
import { CreateUnity } from './Modals/CreateUnity'


const UnityList = () => {
	const { state } = useLocation();
	const company = state as Types.EmpresaInfo;

	const userType = useSelector((state: State) => state.userType.userType);
	const acessLevel = useSelector(
		(state: State) => state.acessLevel.acessLevel
	);

	const [loading, toggleLoading] = useState(false);
	const [loadingChange, toggleChange] = useState(false);
	const inputCompanyRef = useRef<any>();

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [modalAddUnity, toggleModalUnity] = useState(false);

	const [hasLenght, toggleHasLength] = useState(false);
	const [hasUnity, toggleHasUnity] = useState(false);
	const [unities, setUnities] = useState<Types.FilialInfo[]>([]);

	const [idFilial, setIdFilial] = useState('');
	const [nomeEmpresa, setEmpesa] = useState('');
	const [nomeFilial, setNomeFilial] = useState('');
	const [endereco, setEndereco] = useState('');
	const [cnpj, setCNPJ] = useState('');

	const [filteredUnities, setFilteredUnities] = useState<Types.FilialInfo[]>(
		[]
	);

	const [modalDelete, toggleDelete] = useState(false);

	const getUnities = () => {
		toggleLoading(true);
		axios
			.get(
				defines.apiURL +
					'/api/FilialCRUD?idEmpresa=' +
					Functions.ReplaceSpecialCharacters(company.idEmpresa) +
					'&userTypeRequest=' +
					userType,
				{ withCredentials: true }
			)
			.then((response) => {
				const sorted = Functions.SortResponse(
					response.data.content,
					'nomeFilial'
				);
				setUnities(sorted);
				setFilteredUnities(sorted);
				toggleLoading(false);
			})
			.catch((error) => {
				Functions.ResolveErrors(
					error.response.status,
					error.response.data.code,
					error.response.data.message,
					navigate,
					dispatch,
					error.response.data.content
				);
				toggleLoading(false);
			});
	};

	useEffect(() => {
		getUnities();
	}, [dispatch, navigate]);

	const filterUnityData = (name: string) => {
		if (name.length === 0) {
			setFilteredUnities(unities);
			toggleHasLength(false);
			return;
		}
		toggleHasLength(true);
		const filtered = unities.filter((item) =>
			item.nomeFilial.toUpperCase().includes(name.toUpperCase())
		);
		setFilteredUnities(filtered!);
	};

	const formatCNPJ = (cnpj: string) => {
		if (cnpj.length < 14) cnpj = cnpj.padEnd(14, 'X');
		return `${cnpj.slice(0, 2)}.${cnpj.slice(2, 5)}.${cnpj.slice(5, 8)}/${cnpj.slice(8, 12)}-${cnpj.slice(12, 14)}`;
	};

	const handleUserClick = (filial: Types.FilialInfo) => {
		navigate('/filiais/editar', { state: { filial, company } });
	};


	return (
		<Body.Container>
			<Sidebar
				type={userType}
				content={defines.ContentSidebar.MANAGE_COMPANY}
				tituloPagina='Filiais'
				backAction={() => { navigate('/empresas/editar', { state: company }); }}
			/>
			<Body.PageTitle style={{ fontSize: '14px', color: 'gray', fontWeight: 'bold', marginTop: '10px' }}>{company.nomeEmpresa}</Body.PageTitle>
			<Body.SubTitle>
				Toque em uma filial para visualizar seus detalhes.
			</Body.SubTitle>
			<Body.Row
				style={{
					width: '100%',
					justifyContent: 'space-between',
					alignItems: 'center',
					marginTop: '20px',
				}}
			>
				<Body.FindContent>
					<Body.Find
						placeholder="Buscar filial"
						onChange={(e) => filterUnityData(e.target.value)}
						ref={inputCompanyRef}
					/>
					<Body.Icon
						src={SearchIcon}
						style={{ cursor: 'text' }}
						onClick={() => inputCompanyRef.current?.focus()}
					/>
				</Body.FindContent>
				{acessLevel === defines.CargoTectrol['Suporte'] && (
					<Body.HeaderButton onClick={() => toggleModalUnity(true)}>
						<Body.Icon
							src={AddIcon}
							style={{ position: 'relative', top: 0, left: 0 }}
						/>
					</Body.HeaderButton>
				)}
			</Body.Row>
			<Body.Scroll style={{ marginTop: '20px' }}>
				{filteredUnities.length === 0 ? (
					<Body.Text>Nenhuma filial encontrada</Body.Text>
				) : (
					filteredUnities.map((item, index) => (
						<Body.SelectUser
							onClick={() => handleUserClick(item)}
							key={index.toString()}
						>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									gap: '10px',
								}}
							>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
									}}
								>
									<Body.Text>{item.nomeFilial}</Body.Text>
									<Body.NoteText>
										{formatCNPJ(item.cnpj || '')}
									</Body.NoteText>
								</div>
							</div>
						</Body.SelectUser>
					))
				)}
			</Body.Scroll>
			{loading && <Loading />}
			{modalAddUnity ? (
				<CreateUnity
					toggle={toggleModalUnity}
					getData={getUnities}
					idEmpresa={company.idEmpresa}
				/>
			) : null}
		</Body.Container>
	);
};

export default UnityList;
