import React, { useEffect, useRef, useState } from 'react';
import Sidebar from '../../components/SideBar/Sidebar';
import * as Body from './Styles';
import SearchIcon from '../../../assets/icons/Search.svg';
import AddIcon from '../../../assets/icons/add-icon.svg';
import * as Functions from '../../../utils/functions/Functions';
import * as defines from '../../../utils/defines/Defines';
import * as Types from '../../../utils/types/Types';
import Input from '../../components/Inputs/Input/Input';
import Button from '../../components/Buttons/Button/Button';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../components/Loading/Loading';
import IsAuth from '../../components/Modals/IsAuth/IsAuth';
import { State } from '../../redux/store';
import Avatar from '../../../assets/images/avatar.png';
import SendInvite from './Modals/SendInvite';
import ButtonWithoutBG from '../../components/Buttons/ButtonWithoutBG/Button';
import ButtonWrapper from '../../components/Buttons/ButtonWrapper/ButtonWrapper';
import DeleteIcon from '../../../assets/icons/delete-red-material-icon.svg';
import { set } from 'date-fns';

type FilialProps = {
	idFilial: string;
	nomeFilial: string;
	nomeEmpresa: string;
	endereco: string;
	cnpj: string;
	cnpjFilial: string;
	tipoUsuarioEmpresarial: number | null;
};

type UsuarioProps = {
	idEmpresarial: string;
	idUsuario: string;
	fotoUsuario: string | null;
	ddd: string;
	celular: string;
	nomeCompleto: string;
	email: string;
	cargo: number;
	encarregadoDados: number;
};

const DefaultUserList = () => {
	const acessLevel = useSelector(
		(state: State) => state.acessLevel.acessLevel
	);
	const userType = useSelector((state: State) => state.userType.userType);
	const selectedUnity = useSelector(
		(state: State) => state.selectedUnity.selectedUnity
	);

	const [hasLength, toggleHasLength] = useState(false);
	const [hasUser, toggleHasUser] = useState(false);
	const [modalCreateUser, toggleCreateUser] = useState(false);
	const [modalDelete, toggleDelete] = useState(false);
	const [data, setData] = useState<UsuarioProps[]>([]);
	const [filteredUsers, setFiltered] = useState<UsuarioProps[]>([]);
	const [loading, toggleLoading] = useState(true);
	const [changeCargo, toggleChangeCargo] = useState(false);

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [nomeCompleto, setNome] = useState('');
	const [email, setEmail] = useState('');
	const [ddd, setDDD] = useState('');
	const [celular, setTelefone] = useState('');
	const [imagemUsuario, setImagem] = useState('');
	const [nivelAcesso, setNivel] = useState(0);
	const [encarregadoDados, setEncarregado] = useState(0);
	const [idUsuario, setId] = useState('');
	const [loadedUserData, setUserData] = useState<UsuarioProps>();
	const [nomeFilial, setNomeFilial] = useState('');
	const inputRef = useRef<any>();
	const [selectedUser, setSelectedUser] = useState<UsuarioProps>();

	const filterData = (name: string) => {
		if (name.length === 0) {
			setFiltered(data);
			toggleHasLength(false);
			return;
		}
		toggleHasLength(true);
		const filtered = data.filter((item) =>
			item.nomeCompleto.toUpperCase().includes(name.toUpperCase())
		);
		setFiltered(filtered!);
	};

	useEffect(() => {
		if (acessLevel !== defines.CargoEmpresarial['Administrador'])
			navigate('/permissao');

		toggleLoading(true);
		axios
			.get(
				defines.apiURL +
					'/api/EmpresarialCRUD?userTypeRequest=' +
					userType +
					'&idFilial=' +
					Functions.ReplaceSpecialCharacters(selectedUnity),
				{ withCredentials: true }
			)
			.then((response) => {
				const sorted = Functions.SortResponse(
					response.data.content,
					'nomeCompleto'
				);
				setData(sorted);
				setFiltered(sorted);
				toggleLoading(false);
			})
			.catch((error) => {
				Functions.ResolveErrors(
					error.response.status,
					error.response.data.code,
					error.response.data.message,
					navigate,
					dispatch,
					error.response.data.content
				);
				toggleLoading(false);
			});

		axios
			.get(
				defines.apiURL +
					'/api/Home?userTypeRequest=' +
					userType +
					'&filialInfo=' +
					Functions.ReplaceSpecialCharacters(selectedUnity),
				{ withCredentials: true }
			)
			.then((response) => {
				setNomeFilial(response.data.content.nomeFilial);
				toggleLoading(false);
			})
			.catch((error) => {
				Functions.ResolveErrors(
					error.response.status,
					error.response.data.code,
					error.response.data.message,
					navigate,
					dispatch,
					error.response.data.content
				);
				toggleLoading(false);
			});
	}, [dispatch, navigate]);

	const getCargo = (cargo: number) => {
		switch (cargo) {
			case defines.CargoEmpresarial['Administrador']:
				return 'Administrador';
			case defines.CargoEmpresarial['Padrão']:
				return 'Padrão';
			default:
				return 'Cargo Indisponível';
		}
	};

	const handleUserClick = (user: UsuarioProps) => {
		navigate('/usuarios-empresarial/editar', { state: { user: user} });
	};

	return (
		<Body.Container>
			<Sidebar
				type={userType}
				content={defines.ContentSidebar.MANAGE_USERS}
				tituloPagina="Usuários"
			/>
			<Body.SubTitle style={{ marginTop: '10px' }}>
				Toque em um usuário para visualizar seus detalhes.
			</Body.SubTitle>
			<Body.Row
				style={{
					width: '100%',
					alignItems: 'center',
					marginTop: '20px',
				}}
			>
				<Body.FindContent style={{ width: '100%' }}>
					<Body.Find
						placeholder="Buscar usuário"
						onChange={(e) => filterData(e.target.value)}
						ref={inputRef}
					/>
					<Body.Icon
						src={SearchIcon}
						style={{ cursor: 'text' }}
						onClick={() => inputRef.current?.focus()}
					/>
				</Body.FindContent>
				{acessLevel === defines.CargoEmpresarial['Administrador'] && (
					<Body.HeaderButton onClick={() => toggleCreateUser(true)}>
						<Body.Icon
							src={AddIcon}
							style={{ position: 'relative', top: 0, left: 0 }}
						/>
					</Body.HeaderButton>
				)}
			</Body.Row>
			<Body.Scroll style={{ marginTop: '20px' }}>
				{filteredUsers.length === 0 ? (
					<Body.Text>Nenhum usuário encontrado</Body.Text>
				) : (
					filteredUsers.map((item, index) => (
						<Body.SelectUser
							onClick={() => handleUserClick(item)}
							key={index.toString()}
						>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									gap: '10px',
								}}
							>
								<Body.Photo src={item.fotoUsuario || Avatar} />
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
									}}
								>
									<Body.Text>{item.nomeCompleto}</Body.Text>
									<Body.NoteText>
										{getCargo(item.cargo)}
									</Body.NoteText>
								</div>
							</div>
						</Body.SelectUser>
					))
				)}
			</Body.Scroll>

			{modalCreateUser ? (
				<SendInvite
					toggle={toggleCreateUser}
					nomeFilial={nomeFilial || 'atual'}
					idFilial={selectedUnity}
				/>
			) : null}
			{loading && <Loading />}
		</Body.Container>
	);
};

export default DefaultUserList;
