import styled, { keyframes } from 'styled-components';

const animateBackground = keyframes`
    0% {
        backdrop-filter: blur(12px);
        background: rgba(255, 255, 255, 0.7);
    }
    50% {
        backdrop-filter: blur(8px);
        background: rgba(255, 255, 255, 0.9);
    }
    100% {
        backdrop-filter: blur(12px);
        background: rgba(255, 255, 255, 0.7);
    }
`;

export const Container = styled.div`
    position: -webkit-fixed;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(255, 255, 255, 0.6);
    -webkit-backdrop-filter: blur(8px); /* Safari support */
    backdrop-filter: blur(8px);
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    top: 0;
    left: 0;
    z-index: 100;
    animation: 3s ${animateBackground} infinite;
`;

const animateIcon = keyframes`
    0%{
        transform: rotate(0deg);
        width: 100px;
        height: 100px;
    }
    20%{
        width: 100px;
        height: 100px;
    }
    40%{
        width: 110px;
        height: 110px;
    }
    60%{
        width: 110px;
        height: 110px;
    }
    80%{
        width: 100px;
        height: 100px;
    }
    100%{
        transform: rotate(360deg);
    }
`;

export const Icon = styled.img`
	width: 100px;
	height: 100px;
	animation: 2s ${animateIcon} infinite;
`;
