import styled, { keyframes } from 'styled-components';
import * as defines from '../../../utils/defines/Defines';


export const TitleRow = styled.div`
	display: flex;
	justify-content: flex-start;
	grid-gap: 0px 5px;
`;

export const ResultContent = styled.div`
	display: flex;
	flex-direction: column;
`;

export const Root = styled.a`
	text-align: left;
	font-family: 'Nunito';
	font-size: 18px;
	color: #6d7989;
`;

export const Page = styled.a`
	text-align: left;
	font-family: 'Nunito';
	font-size: 18px;
	color: #000;
`;

export const AppCompany = styled.a`
	text-align: left;
	font-family: 'Nunito';
	font-weight: bold;
	font-size: 18px;
	color: #000;
`;

export const UserIcons = styled.img`
	width: 24px;
	height: 24px;
	border-radius: 12px;
`;

export const Filial = styled.a`
	font-family: 'Nunito';
	font-size: 14px;
	color: ${defines.BlueTec};
`;

export const ButtonContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-start;
	margin-left: 100px;
`;

export const IconWrapper = styled.div<{ hasSubscription: boolean }>`
	width: 120px;
	height: 120px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${({ hasSubscription }) =>
		hasSubscription ? defines.GreenTec : '#F34A4A'};
	border-radius: 50%;
	min-width: 120px;
	max-width: 120px;
	min-height: 120px;
	max-height: 120px;
`;

export const PlanDescription = styled.a`
	font-family: 'Nunito';
	font-size: 18px;
	color: #000;
	margin-bottom: 5px;
	margin-top: -20px;
	height: 80px;
`;

export const Expiration = styled.a`
	margin-bottom: -20px;
	font-family: 'Nunito';
	font-size: 12px;
	color: #6d7989;
`;

export const ExpirationDate = styled.a`
	font-family: 'Nunito';
	font-size: 16px;
	color: #000;
`;

export const SmallIconWrapper = styled.div`
	width: 30px;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${defines.BlueTec};
	border-radius: 50%;
	margin-left: 90px;
`;

export const SmallIcon = styled.img`
	width: 14px;
	height: 14px;
	border-radius: 12px;
`;

export const ActivePlanTitle = styled.a`
	display: flex;
	justify-content: flex-start;
	grid-gap: 0px 5px;
`;

export const PlanTitle = styled.a`
	font-family: 'Nunito';
	font-size: 18px;
	color: #000;
	margin-bottom: 10px;
`;

export const HorizontalCard = styled.div`
	width: 530px;
	height: 60px;
	background-color: #ffffff;
	border-radius: 20px;
	padding: 20px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	box-sizing: border-box;
`;

export const UserName = styled.a`
	font-family: 'Nunito';
	font-size: 16px;
	color: #000;
`;

export const UserRole = styled.a`
	font-family: 'Nunito';
	font-size: 16px;
	color: #6d7989;
`;

export const UsuariosVinculados = styled.div`
	display: flex;
	justify-content: flex-start;
	justify-content: space-between;
	grid-gap: 0px 5px;
`;
export const UsuariosVinculadosTitle = styled.a`
	font-family: 'Nunito';
	font-size: 18px;
	color: #000;
	margin-bottom: 10px;
`;

export const UsuariosVinculadosIcons = styled.div`
	display: flex;
	justify-content: flex-start;
`;
export const UsuariosVinculadosAddIcon = styled.div`
	width: 30px;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${defines.BlueTec};
	border-radius: 50%;
	margin-left: 10px;
`;

export const UsuariosVinculadosSearchIcon = styled.div`
	width: 30px;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 2px solid ${defines.BlueTec};
	border-radius: 50%;
	margin-left: 10px;
`;

export const ModalIcon = styled.img`
	top: 7.5px;
	left: 7.5px;
	width: 15px;
	height: 15px;
	position: absolute;
	z-index: 3;
`;

export const SubscriptionCard = styled.div`
	grid-gap: 0px 10px;
	width: 100%;
	height: 160px;
	border-radius: 12px;
	border: 1px solid rgba(200, 200, 200, 0.5);
	margin-bottom: 10px;
	display: flex;
	align-items: center;
	padding: 20px;
	box-sizing: border-box;
	justify-content: space-between;
	transition: 0.5s;
	:hover {
		transition: 0.5s;
		background: rgba(0, 0, 0, 0.02);
	}
`;

export const RedIcon = styled.div`
	width: 25px;
	height: 25px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #f34a4a;
	border-radius: 50%;
	img {
		width: 20px;
		height: 20px;
	}
`;

export const GreenIcon = styled.div`
	width: 25px;
	height: 25px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #92b258;
	border-radius: 50%;
	img {
		width: 20px;
		height: 20px;
	}
`;
export const CompanyStatus = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	grid-gap: 10px;
	font-family: 'Nunito';
	font-size: 14px;
`;

export const Empresa = styled.a`
	font-family: 'Nunito';
	font-size: 14px;
	color: #6d7989;
`;

export const Separator = styled.div`
	width: 100%;
	height: 1px;
	background-color: #e0e0e0;
	margin: 20px 0;
`;

export const HeaderIcon = styled.img`
	top: 7.5px;
	left: 7.5px;
	width: 15px;
	height: 15px;
	position: absolute;
	z-index: 3;
`;

export const Cargo = styled.a`
	font-family: 'Nunito';
	font-size: 14px;
	color: grey;
	margin-bottom: 5px;
`;

export const PlanName = styled.a`
	font-family: 'Nunito';
	font-size: 20px;
	color: #000;
	margin-bottom: 10px;
`;

export const SelectCard = styled.div<{ active?: boolean }>`
	width: 150px;
	border-radius: 12px;
	color: #fff;
	padding: 20px;
	border: 1px solid
		${(props) => (props.active ? defines.BlueTec : 'rgba(0,0,0,0.05)')};
	cursor: pointer;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	grid-gap: 10px 0px;
`;

export const IconChangePermission = styled.img`
	width: 80px;
	height: 80px;
	border-radius: 40px;
	background-color: ${defines.BackgroundColor};
`;

export const ModalCardHistory = styled.div`
	width: 100%;
	border-radius: 20px;
	padding: 10px;
	display: flex;
	flex-direction: column;
	align-items: space-between;
	box-sizing: border-box;
	grid-gap: 15px 0px;
	transition: 0.5s;
	background: white;

	&:hover {
		background: #ebebeb;
	}
`;

export const Container = styled.div`
	background: ${defines.BackgroundColor};
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: start;
	position: relative;
	align-items: center;
	
	& > *:nth-child(n+3) {
		padding-left: 20px;
		padding-right: 20px;
	}
`;

export const Content = styled.div`
	width: calc(100vw - 428px);
	height: 100%;
	display: flex;
	flex-direction: column;
	grid-gap: 10px 10px;
	justify-content: center;
	align-items: flex-start;
	transition: 0.5s;
`;
export const HalfContent = styled.div`
	display: flex;
	flex-direction: column;
	grid-gap: 10px 10px;
	max-height: 100%;
	min-width: 400px;
	transition: 0.5s;
	padding: 10px;
	box-sizing: border-box;
`;

export const HeaderButton = styled.div`
	background-color: ${defines.BlueTec};
	width: 30px;
	height: 30px;
	min-height: 30px;
	min-width: 30px;
	border-radius: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	:hover {
		transition: 0.3s;
		opacity: 0.8;
	}
`;

export const FindContent = styled.div`
	min-width: 30px;
	position: relative;
	width: 100%;
`;

export const Icon = styled.img`
	width: 60px;
	height: 60px;

`;

export const Scroll = styled.div`
	width: 100%;
	padding-top: 10px;
	overflow-y: scroll;
	::-webkit-scrollbar {
		display: none;
	}
	box-sizing: border-box;
	max-height: 70vh;
`;

export const CardInput = styled.div`
	display: flex;
	grid-gap: 0px 10px;
	padding: 10px;
	box-sizing: border-box;
	background: rgba(0, 0, 0, 0.02);
	border-radius: 5px;
	cursor: pointer;
`;

export const Find = styled.input`
	height: 30px;
	width: 100%;
	border-radius: 15px;
	border: 1px solid #1080d6;
	padding-left: 30px;
	font-family: 'Nunito';
	background: none;
	z-index: 5;
`;

export const Title = styled.h1`
	font-family: 'Nunito';
	font-weight: bold;
	font-size: 22px;
	color: #000;
`;

export const Row = styled.div`
	display: flex;
	grid-gap: 0px 10px;
`;

export const SubTitle = styled.p`
	font-family: 'Nunito';
	font-size: 14px;
	color: #6d7989;
	margin-bottom: 5px;
`;
export const CardUnit = styled.div`
	width: 100%;
	height: 45px;
	background: #fff;
	border-radius: 12px;
	border: 1px solid rgba(200, 200, 200, 0.5);
	outline: none;
	transition: 0.35s;
	display: flex;
	align-items: center;
	padding-left: 15px;
	box-sizing: border-box;
	cursor: pointer;
`;

export const TextUnit = styled.p`
	font-family: 'Comfortaa';
	white-space: nowrap;
	overflow: hidden;
	text-overflow: clip;
	font-size: 13px;
`;

export const Text = styled.p`
	font-family: 'Nunito';
	font-size: 14px;
	color: rgba(0, 0, 0, 0.8);
`;
export const Card = styled.div`
	width: 90%;
	display: flex;
	flex-direction: column;
	justify-content: start;
	align-items: center;
	background-color: #ffffff;
	border-radius: 20px;
	padding: 20px;
	box-sizing: border-box;
	grid-gap: 20px 0px;
	text-align: center;
`;

export const SelectUser = styled.div`
	grid-gap: 0px 10px;
	width: 100%;
	height: 80px;
	border-radius: 12px;
	border: 1px solid rgba(200, 200, 200, 0.5);
	cursor: pointer;
	margin-bottom: 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px;
	box-sizing: border-box;
	transition: 0.5s;
	:hover {
		transition: 0.5s;
		background: rgba(0, 0, 0, 0.02);
	}
`;

export const Photo = styled.img`
	width: 40px;
	height: 40px;
	border-radius: 20px;
`;

export const Column = styled.div`
	display: flex;
	flex-direction: column;
	// grid-gap: 10px 0px;
	align-items: left;
`;

export const ButtonTitle = styled.a`
	font-family: 'Comfortaa';
	font-size: 16px;
	font-weight: bold;
	color: #000;
	max-width: 300px;
	word-wrap: break-word;
`;

export const ModalCardIcon = styled.img`
	margin-right: 15px;
	width: 47px;
	z-index: 3;
`;

const CardAnimate = keyframes`
    from{
        right: -10vw;
        opacity: 0;
    }
    to{
        right: 80px;
        opacity: 1;
    }
`;

export const ModalCard = styled.div`
	width: 100%;
	border-radius: 20px;
	padding: 10px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	box-sizing: border-box;
	grid-gap: 15px 0px;
	animation: 0.5s ${CardAnimate} ease-in;
	transition: 0.5s;
	background: white;

	&:hover {
		background: #ebebeb;
	}
`;
const TextAnimate = keyframes`
    from{
        margin-top: -10px;
        opacity: 0;
    }
    to{
        margin-top: 0px;
        opacity: 1;
    }
`;

export const TextError = styled.a`
	font-family: 'Comfortaa';
	font-size: 10px;
	color: ${defines.Red};
	animation: 0.35s ${TextAnimate} ease-in;
`;

export const ToggleBtn = styled.div<{ active: boolean }>`
	width: 30px;
	height: 30px;
	margin-right: 10px;
	border-radius: 6px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${(props) => (props.active ? defines.BlueTec : '#FFF')};
	border: 2px solid ${(props) => (props.active ? defines.BlueTec : 'gray')};
	transition: 0.15s;
`;
export const IconCheckbox = styled.img`
	width: 15px;
`;

export const NoteText = styled.a`
	font-family: 'Comfortaa';
	font-size: 12px;
	color: #6d7989;
`;

export const PageTitle = styled.a`
	text-align: center;
	font-family: 'Comfortaa';
	font-weight: bold;
	font-size: 24px;
	margin-bottom: 10px;
`;

export const CardIcon = styled.img`
	width: 50px;
`;

export const TextWarning = styled.a`
	font-family: 'Comfortaa';
	font-size: 10px;
	color: ${defines.Yellow};
	animation: 0.35s ${TextAnimate} ease-in;
`;

export const Tabs = styled.div`
	display: -webkit-flex; /* Suporte para navegadores antigos */
	display: -ms-flexbox;  /* Suporte para IE10/11 */
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 12px;
	margin-bottom: 20px;
	margin-top: 20px;
	height: 40px;
	max-height: 40px;
	-webkit-border-radius: 12px;  /* Suporte para navegadores antigos */
	-moz-border-radius: 12px;     /* Suporte para Firefox */
	border: 1px solid grey;

	/* Adicionando um fallback para navegadores que não suportam display: flex */
	@supports not (display: flex) {
		display: block; /* Usar o modelo de layout block em navegadores antigos */
	}
`;

export const Tab = styled.a<{ isActive: boolean }>`
	font-family: 'Comfortaa';
	width: 50%;
	padding: 10px;
	height: 40px;
	max-height: 40px;
	text-align: center;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 14px;
	color: ${(props) => (!props.isActive ? 'var(--blue-tec, #007BFF)' : 'white')};
	cursor: pointer;
	-webkit-transition: 0.3s ease-in-out;
	-moz-transition: 0.3s ease-in-out;
	transition: 0.3s ease-in-out;
	background: ${(props) => (props.isActive ? 'var(--blue-tec, #007BFF)' : 'none')};
	border-radius: 0;

	:first-child {
		-webkit-border-top-left-radius: 12px;
		-moz-border-top-left-radius: 12px;
		border-top-left-radius: 12px;
		-webkit-border-bottom-left-radius: 12px;
		-moz-border-bottom-left-radius: 12px;
		border-bottom-left-radius: 12px;
	}

	:last-child {
		-webkit-border-top-right-radius: 12px;
		-moz-border-top-right-radius: 12px;
		border-top-right-radius: 12px;
		-webkit-border-bottom-right-radius: 12px;
		-moz-border-bottom-right-radius: 12px;
		border-bottom-right-radius: 12px;
	}
`;

export const SearchIcon = styled.img`
	top: 7.5px;
	left: 7.5px;
	width: 15px;
	height: 15px;
	position: absolute;
	z-index: 3;
`;

export const AddIcon = styled.img`
	width: 18px;
	height: 18px;
`;