import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Input from '../../../components/Inputs/Input/Input';
import Modal from '../../../components/Modals/Modal/Modal';
import * as Body from '../Styles';
import Button from '../../../components/Buttons/Button/Button';
import ButtonWrapper from '../../../components/Buttons/ButtonWrapper/ButtonWrapper';
import AdministradorIcon from '../../../../assets/icons/adm-icon.svg';
import EngenheiroIcon from '../../../../assets/icons/usuario-icon.svg';
import * as Functions from '../../../../utils/functions/Functions';
import * as defines from '../../../../utils/defines/Defines';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '../../../../assets/icons/Search.svg';
import { State } from '../../../redux/store';
import ButtonWithoutBG from '../../../components/Buttons/ButtonWithoutBG/Button';

const TipoUsuario = {
	Padrão: 0,
	Administrador: 3,
	Supervisor: 2,
	Tecnico: 1,
	CargoIndisponivel: 0,
};

type FilialProps = {
	idFilial: string;
	nomeFilial: string;
	cnpjFilial: string;
	statusAssinatura: number;
	idEmpresa: string;
	cnpjEmpresa: string;
	nomeEmpresa: string;
};

type UsuarioProps = {
	idUsuario: string;
	nomeCompleto: string;
	email: string;
	visitante: number;
	validade: number;
	cargo: number;
	idVisitante: number;
	idAPI: number;
};

type SelectUnitProps = {
	toggle: Dispatch<SetStateAction<boolean>>;
	filial: FilialProps;
	regenerate: () => void;
	app: string;
};

const InviteDefault: React.FC<SelectUnitProps> = ({
	toggle,
	filial,
	regenerate,
	app,
}) => {
	const userType = useSelector((state: State) => state.userType.userType);

	const [loading, toggleLoading] = useState(false);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [usuarios, setUsuarios] = useState<UsuarioProps[]>([]);
	const [filteredUsuarios, setFilteredUsuarios] = useState<UsuarioProps[]>(
		[]
	);
	const [selectedUser, setSelectedUser] = useState<UsuarioProps>();
	const [selectedRole, setSelectedRole] = useState(0);

	const [showFirstStep, toggleFirstStep] = useState(true);
	const [showSecondStep, toggleSecondStep] = useState(false);

	useEffect(() => {
		toggleLoading(true);
		axios
			.get(
				defines.apiURL +
					'/api/AssinaturaCRUD?linkedUsers=1&api=' +
					app +
					'&idFilial=' +
					Functions.ReplaceSpecialCharacters(filial.idFilial) +
					'&userTypeRequest=' +
					userType,
				{ withCredentials: true }
			)
			.then((response) => {
				setUsuarios(response.data.content.availableUsersList);
				setFilteredUsuarios(response.data.content.availableUsersList);
			})
			.catch((error) => {
				Functions.ResolveErrors(
					error.response.status,
					error.response.data.code,
					error.response.data.message,
					navigate,
					dispatch,
					error.response.data.content
				);
			});
		toggleLoading(false);
	}, [navigate, dispatch]);

	const filterData = (name: string) => {
		if (name.length === 0) {
			setFilteredUsuarios(usuarios);
			return;
		}

		const filtered = usuarios.filter((item) => {
			return item.nomeCompleto.toLowerCase().includes(name.toLowerCase());
		});

		setFilteredUsuarios(filtered);
	};

	const selectUser = (user: UsuarioProps) => {
		setSelectedUser(user);
		toggleFirstStep(false);
		toggleSecondStep(true);
	};

	const handleSelectRole = (role: number) => {
		setSelectedRole(role); // Atualiza o tipo de usuário selecionado
	};

	const getCargo = (role: number) => {
		switch (role) {
			case TipoUsuario.Administrador:
				return 'Administrador';
			case TipoUsuario.Supervisor:
				return 'Supervisor';
			case TipoUsuario.Tecnico:
				return 'Técnico';
			default:
				return 'Cargo Indisponível';
		}
	};

	const invite = () => {
		toggleLoading(true);
		const payload = {
			idUsuario: selectedUser?.idUsuario,
			...(app !== 'REL' && { nivelAcesso: selectedRole }),
		};

		const apiEndpoint =
			app === 'INS'
				? '/api/InSightCRUD'
				: app === 'DHM'
					? '/api/DHMCRUD'
					: '/api/RelatorioCRUD';
		axios
			.post(
				defines.apiURL +
					apiEndpoint +
					'?idFilial=' +
					Functions.ReplaceSpecialCharacters(filial.idFilial) +
					'&userTypeRequest=' +
					userType,
				payload,
				{ withCredentials: true }
			)
			.then((response) => {
				toggleLoading(false);
				toggle(false);
				dispatch({
					type: 'ADD_NOTIFICATION',
					status: 1,
					title: '',
					text: 'Convite enviado com sucesso!',
				});
				regenerate();
			})
			.catch((error) => {
				toggleLoading(false);
				Functions.ResolveErrors(
					error.response.status,
					error.response.data.code,
					error.response.data.message,
					navigate,
					dispatch,
					error.response.data.content
				);
			});
	};

	const firstStep = () => {
		return (
			<>
				<Body.FindContent style={{ width: '100%' }}>
					<Body.Find
						onChange={(e) => filterData(e.target.value)}
						style={{ maxWidth: '100%', width: '100%' }}
					/>
					<Body.ModalIcon src={SearchIcon} />
				</Body.FindContent>
				<Body.Scroll>
					{usuarios.length !== 0 ? (
						filteredUsuarios.map((item, index) => {
							return (
								<Body.SelectUser
									onClick={() => {
										selectUser(item);
									}}
								>
									<Body.CompanyStatus>
										{item.nomeCompleto}
									</Body.CompanyStatus>
									<Body.NoteText>
										{getCargo(item.cargo)}
									</Body.NoteText>
								</Body.SelectUser>
							);
						})
					) : (
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								height: '100%',
							}}
						>
							<ButtonWithoutBG
								text="Não existem usuários disponíveis."
								action={() => {
									toggle(false);
								}}
							/>
						</div>
					)}
				</Body.Scroll>
			</>
		);
	};

	const secondStep = () => {
		return (
			<>
				<Body.Row>
					<Body.Title>{selectedUser?.nomeCompleto}</Body.Title>
				</Body.Row>
				<Body.Row>
					<Body.Text>Selecione o nível de acesso</Body.Text>
				</Body.Row>

				{app === 'REL' ? (
					<>
						<ButtonWrapper
							style={{ width: '100%' }}
							action={() =>
								handleSelectRole(TipoUsuario['Padrão'])
							}
						>
							<Body.ModalCard
								style={{
									backgroundColor:
										selectedRole ===
										TipoUsuario['Padrão']
											? '#ebebeb'
											: 'white',
								}}
							>
								<Body.Row>
									<Body.ModalCardIcon
										src={AdministradorIcon}
									/>
									<Body.Column>
										<Body.ButtonTitle>
											Padrão
										</Body.ButtonTitle>
										<Body.Text>
											Permissão de acesso total de leitura e escrita ao aplicativo Relatórios.
										</Body.Text>
									</Body.Column>
								</Body.Row>
							</Body.ModalCard>
						</ButtonWrapper>
					</>
				) : (
					<>
						<ButtonWrapper
							style={{ width: '100%' }}
							action={() =>
								handleSelectRole(TipoUsuario.Administrador)
							}
						>
							<Body.ModalCard
								style={{
									backgroundColor:
										selectedRole ===
										TipoUsuario.Administrador
											? '#ebebeb'
											: 'white',
								}}
							>
								<Body.Row>
									<Body.ModalCardIcon
										src={AdministradorIcon}
									/>
									<Body.Column>
										<Body.ButtonTitle>
											Administrador
										</Body.ButtonTitle>
										<Body.Text>
											Gerencia usuários e aplicação.
										</Body.Text>
									</Body.Column>
								</Body.Row>
							</Body.ModalCard>
						</ButtonWrapper>
						<ButtonWrapper
							style={{ width: '100%' }}
							action={() =>
								handleSelectRole(TipoUsuario.Supervisor)
							}
						>
							<Body.ModalCard
								style={{
									backgroundColor:
										selectedRole === TipoUsuario.Supervisor
											? '#ebebeb'
											: 'white',
								}}
							>
								<Body.Row>
									<Body.ModalCardIcon
										src={AdministradorIcon}
									/>
									<Body.Column>
										<Body.ButtonTitle>
											Supervisor
										</Body.ButtonTitle>
										<Body.Text>
											Gerencia parâmetros de
											funcionamento.
										</Body.Text>
									</Body.Column>
								</Body.Row>
							</Body.ModalCard>
						</ButtonWrapper>
						<ButtonWrapper
							style={{ width: '100%' }}
							action={() => handleSelectRole(TipoUsuario.Tecnico)}
						>
							<Body.ModalCard
								style={{
									backgroundColor:
										selectedRole === TipoUsuario.Tecnico
											? '#ebebeb'
											: 'white',
								}}
							>
								<Body.Row>
									<Body.ModalCardIcon
										src={AdministradorIcon}
									/>
									<Body.Column>
										<Body.ButtonTitle>
											Técnico
										</Body.ButtonTitle>
										<Body.Text>
											Visualização da aplicação.
										</Body.Text>
									</Body.Column>
								</Body.Row>
							</Body.ModalCard>
						</ButtonWrapper>
					</>
				)}

				<br />
				<Body.Row>
					<Button
						action={() => {
							invite();
						}}
						text="Convidar Usuário"
					/>
				</Body.Row>
			</>
		);
	};

	const getContent = () => {
		if (showFirstStep) {
			return firstStep();
		} else if (showSecondStep) {
			return secondStep();
		}
	};

	return (
		<Modal
			title={'Convidar Usuário'}
			toggleModal={toggle}
			loading={loading}
		>
			<div
				style={{
					width: '600px',
					display: 'flex',
					flexDirection: 'column',
					gridGap: '15px 0px',
				}}
			>
				{getContent()}
			</div>
		</Modal>
	);
};

export default InviteDefault;
