import styled, { keyframes } from 'styled-components';
import * as defines from '../../../utils/defines/Defines';

export const AnimateOpen = keyframes`
    from{
        right: -200px;
    }
    to{
        right: 0px;
    }
`;

// Animação de fade-in e deslize para baixo
const slideIn = keyframes`
  from {
    opacity: 1;
    transform: translateY(-100vh);
  }
  to {
    opacity: 1;
    transform: translateY(0vh);
  }
`;

const slideOut = keyframes`
  from {
	opacity: 1;
	transform: translateY(0vh);
  }
  to {
	opacity: 1;
	transform: translateY(-100vh);
  }
`;

export const Card = styled.div<{ open: boolean }>`
	width: 100vw;
	height: 100vh;
	border-radius: 20px;
	border: none;
	left: 0;
	top: 0;
	padding-top: 40px;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;

	background-color: ${defines.BackgroundColor};
	box-shadow:
		-10px -10px 30px 1px rgba(255, 255, 255, 0.8),
		10px 10px 30px 1px rgba(211, 211, 212, 0.4);

	position: absolute;
	z-index: 10;

	animation: ${({ open }) => (open ? slideIn : slideOut)} 0.3s ease;
	opacity: ${({ open }) => (open ? 1 : 0)};
	visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
	transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s ease;
`;

export const Photo = styled.img`
	width: 180px;
	height: 180px;
	border-radius: 90px;
`;
export const Title = styled.a`
	font-family: 'Comfortaa';
	font-weight: medium;
	font-size: 20px;
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	grid-gap: 10px 0px;
`;

export const Image = styled.img`
	width: 100px;
	height: 70px;
`;

export const BottomBar = styled.div`
	width: 100%;
	height: 1px;
	background-color: rgba(10, 10, 10, 0.4);
`;

export const StyledBurger = styled.button<{ open: boolean }>`
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	width: 50px;
	height: 50px;
	border: none;
	cursor: pointer;
	padding: 0;
	z-index: 11;
	transition: all 0.3s ease;
	background: transparent;

	&:focus {
		outline: none;
	}

	div {
		width: 2rem;
		height: 0.25rem;
		background: #000;
		border-radius: 10px;
		transition: all 0.3s ease;
		position: relative;
		transform-origin: 1px;
		left: ${({ open }) => (open ? '8px' : '10px')};

		:first-child {
			top: ${({ open }) => (open ? '0px' : '8px')};
			transform: ${({ open }) => (open ? 'rotate(45deg) translateX(9px)' : 'rotate(0)')};
		}

		:nth-child(2) {
			opacity: ${({ open }) => (open ? '0' : '1')};
			transform: ${({ open }) => (open ? 'translateX(6px) translateY(-12px) rotate(45deg)' : 'translateX(0) translateY(0) rotate(0deg)')};
		}

		:nth-child(3) {
			top: ${({ open }) => (open ? '0px' : '-8px')};
			transform: ${({ open }) => (open ? 'rotate(-45deg) translateX(9px)' : 'rotate(0)')};
		}
	}
`;

export const Subtitle = styled.a`
    font-family: "Comfortaa";
    font-weight: medium;
    font-size: 12px;
    margin-top: -8px;
    color: #6D7989;
`;

export const UnityBadge = styled.div`
	font-family: 'Comfortaa';
	font-size: 12px;
	padding: 4px;
	padding-left: 12px;
	padding-right: 12px;
	color: white;
	background-color: #6d7989;
	border-radius: 12px;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const BadgeIcon = styled.img`
	width: 16px;
	height: 16px;
	min-width: 16px;
	min-height: 16px;
	max-width: 16px;
	max-height: 16px;
`;


export const TitleBar = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px;
	background-color:rgb(255, 255, 255);
	width: 100%;
	height: 50px;
	color: #000;
	font-family: 'Comfortaa';
	box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
	z-index: 11;
	font-size: 16px;
`;

export const BackButton = styled.button`
	background: none;
	border: none;
	cursor: pointer;
`;

export const SidebarButton = styled.button`
	background: none;
	border: none;
	cursor: pointer;
`;

export const TitleTextWrapper = styled.div`
  position: relative;
  width: 250px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TitleText = styled.div`
  position: absolute;
	font-size: 16px;
  font-weight: bold;
  transition: opacity 0.5s ease, transform 0.5s ease;

  &.hidden {
    opacity: 0;
    transform: translateY(-10px);
    pointer-events: none;
  }

  &.visible {
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
  }
`;

export const BackIcon = styled.img`
	width: 40px;
	height: 40px;
`;