import React, { useEffect, useRef, useState } from 'react';
import Sidebar from '../../components/SideBar/Sidebar';
import * as Body from './Styles';
import SearchIcon from '../../../assets/icons/Search.svg';
import AddIcon from '../../../assets/icons/add-icon.svg';
import * as Functions from '../../../utils/functions/Functions';
import * as defines from '../../../utils/defines/Defines';
import * as Types from '../../../utils/types/Types';
import Input from '../../components/Inputs/Input/Input';
import Button from '../../components/Buttons/Button/Button';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../components/Loading/Loading';
import IsAuth from '../../components/Modals/IsAuth/IsAuth';
import { State } from '../../redux/store';
import Avatar from '../../../assets/images/avatar.png';
import VisitaIcon from '../../../assets/icons/visita-icon.svg';
import UsuarioIcon from '../../../assets/icons/usuario-icon.svg';
import TecnicoIcon from '../../../assets/icons/tecnico-icon.svg';
import ButtonWrapper from '../../components/Buttons/ButtonWrapper/ButtonWrapper';
import ArrowBack from '../../../assets/icons/arrow-back-preto-icon.svg';
import { ModalDeleteUser } from './Modals/DeleteUser';

type FilialProps = {
	idFilial: string;
	nomeFilial: string;
	nomeEmpresa: string;
	endereco: string;
	cnpj: string;
	cnpjFilial: string;
	tipoUsuarioEmpresarial: number | null;
};
type UsuarioProps = {
	idUsuario: string;
	nomeCompleto: string;
	fotoUsuario: string | null;
	ddd: string;
	celular: string;
	email: string;
	tipoTectrol: number;
};

const EditTectrolUser = () => {
	const { state } = useLocation();
	const user = state as UsuarioProps;

	const userType = useSelector((state: State) => state.userType.userType);
	const acessLevel = useSelector(
		(state: State) => state.acessLevel.acessLevel
	);

	const [hasUser, toggleHasUser] = useState(false);
	const [modalDelete, toggleDelete] = useState(false);
	const [data, setData] = useState<UsuarioProps[]>([]);
	const [loading, toggleLoading] = useState(true);

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [nomeCompleto, setNome] = useState('');
	const [email, setEmail] = useState('');
	const [ddd, setDDD] = useState('');
	const [celular, setTelefone] = useState('');
	const [imagemUsuario, setImagem] = useState('');
	const [idUsuario, setId] = useState('');
	const [tipoTectrol, setTipoTectrol] = useState(0);
	const [loadedUserData, setUserData] = useState<UsuarioProps>();
	const inputRef = useRef<any>();

	const [errorEmail, setErrorEmail] = useState(false);

	const auxSetEmail = (value: string) => {
		if (Functions.verifyEmail(value)) {
			setEmail(value);
			setErrorEmail(false);
		} else {
			setEmail(value);
			setErrorEmail(true);
		}
	};

	const getCargo = (id: number) => {
		switch (id) {
			case defines.CargoTectrol['Comercial']:
				return 'Comercial';
			case defines.CargoTectrol['Engenheiro']:
				return 'Engenharia';
			case defines.CargoTectrol['Suporte']:
				return 'Suporte';
			case defines.CargoTectrol['Padrão']:
				return 'Padrão';
			default:
				return 'Cargo Indisponível';
		}
	};

	useEffect(() => {
		setNome(user.nomeCompleto);
		setEmail(user.email);
		setDDD(user.ddd);
		setTelefone(user.celular);
		setImagem(user.fotoUsuario || '');
		setId(user.idUsuario);
		setTipoTectrol(user.tipoTectrol);
		setUserData(user);
		toggleHasUser(true);
		toggleLoading(false);
	}, [dispatch, navigate]);

	const updateUser = () => {
		if (!loadedUserData) return;

		const updatedFields: Partial<UsuarioProps> = {};

		if (email !== loadedUserData.email) updatedFields.email = email;
		if (celular !== loadedUserData.celular) updatedFields.celular = celular;
		if (ddd !== loadedUserData.ddd) updatedFields.ddd = ddd;
		if (nomeCompleto !== loadedUserData.nomeCompleto)
			updatedFields.nomeCompleto = nomeCompleto;

		if (Object.keys(updatedFields).length === 0) return;

		axios
			.put(
				defines.apiURL + '/api/UpdateUser?userTypeRequest=' + userType,
				{
					...updatedFields,
					idUsuario: idUsuario,
					tipoTectrol: tipoTectrol,
				},
				{ withCredentials: true }
			)
			.then((response) => {
				dispatch({
					type: 'ADD_NOTIFICATION',
					status: 1,
					title: '',
					text: 'Dados do usuario alterados com sucesso',
				});
			})
			.catch((error) => {
				Functions.ResolveErrors(
					error.response.status,
					error.response.data.code,
					error.response.data.message,
					navigate,
					dispatch,
					error.response.data.content
				);
			});
	};

	const deleteUser = () => {
		toggleLoading(true);
		axios
			.delete(
				defines.apiURL +
					'/api/TectrolCRUD?id=' +
					Functions.ReplaceSpecialCharacters(idUsuario) +
					'&userTypeRequest=' +
					userType,
				{ withCredentials: true }
			)
			.then((response) => {
				dispatch({
					type: 'ADD_NOTIFICATION',
					status: 1,
					text: 'Usuário excluido com sucesso',
					title: '',
				});
				toggleHasUser(false);
				toggleDelete(false);
				toggleLoading(false);
				navigate('/tectrol');
			})
			.catch((error) => {
				Functions.ResolveErrors(
					error.response.status,
					error.response.data.code,
					error.response.data.message,
					navigate,
					dispatch,
					error.response.data.content
				);
				toggleLoading(false);
			});
	};


	return (
		<Body.Wrapper>
				<Sidebar
					type={userType}
					content={defines.ContentSidebar.MANAGE_USERS}
					tituloPagina="Editar Administrador"
					backAction={() => {
						navigate('/tectrol');
					}}
				/>
			<Body.Container>

				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						minHeight: '300px',
					}}
				>
					<div
						style={{
							position: 'relative',
							display: 'inline-block',
						}}
					>
						<Body.Photo src={imagemUsuario || Avatar} />
					</div>
				</div>

				<Body.Row>
					<Input
						text={nomeCompleto}
						setText={setNome}
						placeholder="Nome Completo"
						title="Nome Completo"
					/>
				</Body.Row>
				<Body.Row>
					<Input
						text={email}
						setText={auxSetEmail}
						placeholder="Email"
						title="Email"
						autoComplete='email'
					/>
				</Body.Row>
				<Body.Row>
					<Input
						width={'20%'}
						text={ddd}
						setText={setDDD}
						placeholder="DDD"
						title="DDD"
						limit={3}
					/>
					<Input
						width={'80%'}
						text={celular}
						setText={setTelefone}
						placeholder="Telefone"
						title="Telefone"
						limit={9}
					/>
				</Body.Row>
				{errorEmail ? (
					<Body.TextError>
						Formato de email inválido ex: email@email.com
					</Body.TextError>
				) : null}

<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						minHeight: '120px',
					}}
				>
					<div
						style={{
							position: 'relative',
							display: 'inline-block',
						}}
					>
						<Body.Row>
							<Body.Card
								onClick={() =>
									setTipoTectrol(defines.CargoTectrol['Comercial'])
								}
								style={{
									border:
										tipoTectrol ===
										defines.CargoTectrol['Comercial']
											? '2px solid ' + defines.BlueTec
											: 'none',
									transition: 'all 0.3s ease',
								}}
							>
								<Body.CardIcon src={UsuarioIcon} />
								<Body.CardTitle>Comercial</Body.CardTitle>
							</Body.Card>
							<Body.Card
								onClick={() =>
									setTipoTectrol(defines.CargoTectrol['Engenheiro'])
								}
								style={{
									border:
										tipoTectrol ===
										defines.CargoTectrol['Engenheiro']
											? '2px solid ' + defines.BlueTec
											: 'none',
									transition: 'all 0.3s ease',
								}}
							>
								<Body.CardIcon src={TecnicoIcon} />
								<Body.CardTitle>Engenharia</Body.CardTitle>
							</Body.Card>
						</Body.Row>
					</div>
				</div>

				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						minHeight: '120px',
					}}
				>
					<div
						style={{
							position: 'relative',
							display: 'inline-block',
						}}
					>
						<Body.Row>
							<Body.Card
								onClick={() =>
									setTipoTectrol(defines.CargoTectrol['Suporte'])
								}
								style={{
									border:
										tipoTectrol === defines.CargoTectrol['Suporte']
											? '2px solid ' + defines.BlueTec
											: 'none',
									transition: 'all 0.3s ease',
								}}
							>
								<Body.CardIcon src={TecnicoIcon} />
								<Body.CardTitle>Suporte</Body.CardTitle>
							</Body.Card>
							<Body.Card
								onClick={() =>
									setTipoTectrol(defines.CargoTectrol['Padrão'])
								}
								style={{
									border:
										tipoTectrol === defines.CargoTectrol['Padrão']
											? '2px solid ' + defines.BlueTec
											: 'none',
									transition: 'all 0.3s ease',
								}}
							>
								<Body.CardIcon src={UsuarioIcon} />
								<Body.CardTitle>Padrão</Body.CardTitle>
							</Body.Card>
						</Body.Row>
					</div>
				</div>


				<Body.Row>
					<Button
						text="Salvar"
						color="blue"
						action={() => updateUser()}
						disabled={!(acessLevel === defines.CargoTectrol['Suporte'])}
					/>
					<Button
						text="Excluir Usuário"
						color="red"
						action={() => toggleDelete(true)}
						disabled={!(acessLevel === defines.CargoTectrol['Suporte'])}
					/>
				</Body.Row>

				<Body.Row>
				</Body.Row>

				{loading && <Loading />}
				{modalDelete ? (
				<ModalDeleteUser
					toggle={toggleDelete}
					idUsuario={idUsuario}
					nome={nomeCompleto}
					action={deleteUser}
				/>
			) : null}
			</Body.Container>
		</Body.Wrapper>
	);
};

export default EditTectrolUser;
