import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import * as Body from './Styles';
import * as defines from '../../../utils/defines/Defines';
import Input from '../../components/Inputs/Input/Input';
import Button from '../../components/Buttons/Button/Button';
import ButtonWithoutBG from '../../components/Buttons/ButtonWithoutBG/Button';
import CodeInput from '../../components/Inputs/InputCode/Input';
import * as Functions from '../../../utils/functions/Functions';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import LogoConecta from '../../../assets/images/logoConecta.png';

const Content = {
	Login: 1,
	TFA: 2,
};

type LoginProps = {
	email: string;
	setEmail: Dispatch<SetStateAction<string>>;
	senha: string;
	setSenha: Dispatch<SetStateAction<string>>;
	toggleContent: Dispatch<SetStateAction<number>>;
};

type TFAProps = {
	email: string;
	senha: string;
	toggleContent: Dispatch<SetStateAction<number>>;
};

const Inicio: React.FC<LoginProps> = ({
	email,
	setEmail,
	senha,
	setSenha,
	toggleContent,
}) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [errorEmail, toggleErrorEmail] = useState(false);
	const [errorPass, toggleErrorPass] = useState(false);
	const [loading, toggleLoading] = useState(false);

	const trySend = () => {
		axios
			.get(defines.apiURL + '/api/LoginTFA', { withCredentials: true })
			.then((response) => {
				dispatch({
					type: 'ADD_NOTIFICATION',
					status: 1,
					title: '',
					text: 'Código enviado',
				});
			})
			.catch((error) => {
				Functions.ResolveErrors(
					error.response.status,
					error.response.data.code,
					error.response.data.message,
					navigate,
					dispatch,
					error.response.data.content
				);
			});
	};

	const tryLogin = () => {
		toggleErrorEmail(false);
		toggleErrorPass(false);
		if (!Functions.verifyEmail(email) || senha.length < 12) {
			toggleErrorEmail(!Functions.verifyEmail(email));
			toggleErrorPass(senha.length < 12 ? true : false);
			return;
		}

		toggleLoading(true);
		axios
			.post(
				defines.apiURL + '/api/Login',
				{ email, senha, api: process.env.REACT_APP_API_URL! },
				{ withCredentials: true }
			)
			.then((response) => {
				trySend();
				toggleContent(Content.TFA);
				toggleLoading(false);
				return;
			})
			.catch((error) => {
				toggleLoading(false);
				Functions.ResolveErrors(
					error.response.status,
					error.response.data.code,
					error.response.data.message,
					navigate,
					dispatch,
					error.response.data.content
				);
				return;
			});
	};

	return (
		<Body.Content>
			<Input
				text={email}
				setText={setEmail}
				title="Email"
				error={errorEmail}
				autoComplete='email'
			/>
			<Input
				text={senha}
				setText={setSenha}
				title="Senha"
				password={true}
				error={errorPass}
				action={tryLogin}
				autoComplete='current-password'
			/>
			<Button text="Entrar" action={() => tryLogin()} loading={loading} />
			<ButtonWithoutBG
				text="Esqueci minha senha"
				action={() => navigate('/recuperar?email=' + email)}
			/>
		</Body.Content>
	);
};

const TFA: React.FC<TFAProps> = ({ email, senha, toggleContent }) => {
	const [loading, toggleLoading] = useState(false);
	const [lock, toggleLock] = useState(false);
	const [time, setTime] = useState(120);
	const [loadingCode, toggleCode] = useState(false);

	const [tfaCode, setCode] = useState('');
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		if (time === 0) {
			toggleLock(false);
			return;
		}
		const interval = setInterval(() => {
			setTime((seconds) => seconds - 1);
		}, 1000);
		return () => clearInterval(interval);
	}, [time]);

	const tryResend = () => {
		if (lock) {
			return;
		}
		toggleCode(true);
		axios
			.get(defines.apiURL + '/api/LoginTFA', { withCredentials: true })
			.then((response) => {
				toggleLock(true);
				setTime(120);
				toggleCode(false);
				dispatch({
					type: 'ADD_NOTIFICATION',
					status: 1,
					title: 'Código enviado',
				});
			})
			.catch((error) => {
				toggleCode(false);
				switch (error.response.status) {
					case 406:
						if (error.response.data.code === 'TFA-3') {
							setTime(120);
							toggleLock(true);
							dispatch({
								type: 'ADD_NOTIFICATION',
								status: 3,
								title: '',
								text: error.response.data.message,
							});
							return;
						}
				}
				Functions.ResolveErrors(
					error.response.status,
					error.response.data.code,
					error.response.data.message,
					navigate,
					dispatch,
					error.response.data.content
				);
			});
	};

	const tryAuth = () => {
		toggleLoading(true);
		axios
			.post(
				defines.apiURL + '/api/LoginTFA',
				{ tfaCode, api: process.env.REACT_APP_API_URL! },
				{ withCredentials: true }
			)
			.then((response) => {
				localStorage.setItem('@logged', 'true');
				toggleLoading(false);
				navigate('/menu');
			})
			.catch((error) => {
				Functions.ResolveErrors(
					error.response.status,
					error.response.data.code,
					error.response.data.message,
					navigate,
					dispatch,
					error.response.data.content
				);
				toggleLoading(false);
			});
	};

	return (
		<Body.Content>
			<Body.Text>
				Enviamos um código de segurança para seu celular e email.
				Insira-o para prosseguirmos com seu login.
			</Body.Text>
			<CodeInput setText={setCode} action={tryAuth} title="Código" />
			<Button text="Continuar" action={tryAuth} loading={loading} />
			{lock ? (
				<Body.Text>
					Aguarde {Functions.getTime(time)} para enviar novamente
				</Body.Text>
			) : (
				<ButtonWithoutBG
					text="Reenviar código"
					loading={loadingCode}
					action={tryResend}
				/>
			)}
		</Body.Content>
	);
};

const Login = () => {
	const [email, setEmail] = useState('');
	const [senha, setSenha] = useState('');

	const [content, toggleContent] = useState(Content.Login);

	const navigate = useNavigate();

	const getContent = () => {
		switch (content) {
			case Content.Login:
				return (
					<Inicio
						email={email}
						setEmail={setEmail}
						senha={senha}
						setSenha={setSenha}
						toggleContent={toggleContent}
					/>
				);
			case Content.TFA:
				return (
					<TFA
						email={email}
						senha={senha}
						toggleContent={toggleContent}
					/>
				);
		}
	};

	useEffect(() => {
		if (localStorage.getItem('@logged') == 'true') {
			navigate('/menu');
		}
	}, []);

	return (
		<Body.Container>
			<Body.TitleContent>
				<Body.Row
					style={{ justifyContent: 'center', marginTop: '30px' }}
				>
					<Body.CompanyLogo
						style={{ height: '80px' }}
						src={LogoConecta}
					/>
				</Body.Row>
			</Body.TitleContent>
			{getContent()}
		</Body.Container>
	);
};

export default Login;
