import React, { useEffect, useRef, useState } from 'react';
import Sidebar from '../../components/SideBar/Sidebar';
import * as Body from './Styles';
import SearchIcon from '../../../assets/icons/Search.svg';
import AddIcon from '../../../assets/icons/add-icon.svg';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../components/Loading/Loading';
import Avatar from '../../../assets/images/avatar.png';
import * as Functions from '../../../utils/functions/Functions';
import * as defines from '../../../utils/defines/Defines';
import { State } from '../../redux/store';
import * as Types from '../../../utils/types/Types';
import * as Modals from './Modals/Modals';

const CompanyList = () => {
	const acessLevel = useSelector(
		(state: State) => state.acessLevel.acessLevel
	);
	const userType = useSelector((state: State) => state.userType.userType);

	const [loading, toggleLoading] = useState(false);
	const [loadingChange, toggleChange] = useState(false);
	const inputCompanyRef = useRef<any>();

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [modalAddCompany, toggleModalCompany] = useState(false);

	const [hasLenght, toggleHasLength] = useState(false);
	const [hasCompany, toggleHasCompany] = useState(false);
	const [companies, setCompanies] = useState<Types.EmpresaInfo[]>([]);
	const [nomeEmpresa, setEmpesa] = useState('');
	const [cnpj, setCNPJ] = useState('');
	const [idEmpresa, setId] = useState('');
	const [filteredCompanies, setFilteredCompanies] = useState<
		Types.EmpresaInfo[]
	>([]);

	const [modalDelete, toggleDelete] = useState(false);

	const getCompanies = () => {
		toggleLoading(true);
		axios
			.get(
				defines.apiURL + '/api/EmpresaCRUD?userTypeRequest=' + userType,
				{ withCredentials: true }
			)
			.then((response) => {
				const sorted = Functions.SortResponse(
					response.data.content,
					'nomeEmpresa'
				);
				setCompanies(sorted);
				setFilteredCompanies(sorted);
				toggleLoading(false);
			})
			.catch((error) => {
				Functions.ResolveErrors(
					error.response.status,
					error.response.data.code,
					error.response.data.message,
					navigate,
					dispatch,
					error.response.data.content
				);
				toggleLoading(false);
			});
	};

	useEffect(() => {
		toggleLoading(true);
		axios
			.get(
				defines.apiURL + '/api/EmpresaCRUD?userTypeRequest=' + userType,
				{ withCredentials: true }
			)
			.then((response) => {
				const sorted = Functions.SortResponse(
					response.data.content,
					'nomeEmpresa'
				);
				setCompanies(sorted);
				setFilteredCompanies(sorted);
				toggleLoading(false);
			})
			.catch((error) => {
				Functions.ResolveErrors(
					error.response.status,
					error.response.data.code,
					error.response.data.message,
					navigate,
					dispatch,
					error.response.data.content
				);
				toggleLoading(false);
			});
	}, [dispatch, navigate]);

	const filterCompanyData = (name: string) => {
		if (name.length === 0) {
			setFilteredCompanies(companies);
			toggleHasLength(false);
			return;
		}
		toggleHasLength(true);
		const filtered = companies.filter((item) =>
			item.nomeEmpresa.toUpperCase().includes(name.toUpperCase())
		);
		setFilteredCompanies(filtered!);
	};

	const actionDelete = () => {
		getCompanies();
	};

	const formatCNPJ = (cnpj: string) => {
		if (cnpj.length < 14) cnpj = cnpj.padEnd(14, 'X');
		return `${cnpj.slice(0, 2)}.${cnpj.slice(2, 5)}.${cnpj.slice(5, 8)}/${cnpj.slice(8, 12)}-${cnpj.slice(12, 14)}`;
	};

	const handleUserClick = (empresa: Types.EmpresaInfo) => {
		navigate('/empresas/editar', { state: empresa });
	};


	return (
		<Body.Container>
			<Sidebar
				type={userType}
				content={defines.ContentSidebar.MANAGE_COMPANY}
				tituloPagina='Empresas'
			/>
			<Body.SubTitle style={{ marginTop: '10px' }}>
				Toque em uma empresa para visualizar seus detalhes.
			</Body.SubTitle>
			<Body.Row
				style={{
					width: '100%',
					alignItems: 'center',
					marginTop: '20px',
				}}
			>
				<Body.FindContent>
					<Body.Find
						placeholder="Buscar empresa"
						onChange={(e) => filterCompanyData(e.target.value)}
						ref={inputCompanyRef}
					/>
					<Body.Icon
						src={SearchIcon}
						style={{ cursor: 'text' }}
						onClick={() => inputCompanyRef.current?.focus()}
					/>
				</Body.FindContent>
				{acessLevel === defines.CargoTectrol['Suporte'] && (
					<Body.HeaderButton onClick={() => toggleModalCompany(true)}>
						<Body.Icon
							src={AddIcon}
							style={{ position: 'relative', top: 0, left: 0 }}
						/>
					</Body.HeaderButton>
				)}
			</Body.Row>
			<Body.Scroll style={{ marginTop: '20px' }}>
				{filteredCompanies.length === 0 ? (
					<Body.Text>Nenhuma empresa encontrada</Body.Text>
				) : (
					filteredCompanies.map((item, index) => (
						<Body.SelectUser
							onClick={() => handleUserClick(item)}
							key={index.toString()}
						>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									gap: '10px',
								}}
							>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
									}}
								>
									<Body.Text>{item.nomeEmpresa}</Body.Text>
									<Body.NoteText>
										{formatCNPJ(item.cnpj)}
									</Body.NoteText>
								</div>
							</div>
						</Body.SelectUser>
					))
				)}
			</Body.Scroll>

			{modalAddCompany ? (
				<Modals.AddCompany
					toggle={toggleModalCompany}
					getData={getCompanies}
				/>
			) : null}
			{modalDelete ? (
				<Modals.ConfirmDeleteCompany
					nome={nomeEmpresa}
					id={idEmpresa}
					toggle={toggleDelete}
					action={actionDelete}
				/>
			) : null}
			{loading && <Loading />}
		</Body.Container>
	);
};

export default CompanyList;
