import React, { useState } from 'react';
import * as defines from '../../../utils/defines/Defines';
import * as Body from './TableStyles';
import EditIcon from '../../../assets/icons/edit-icon-blue.svg';
import TrashIcon from '../../../assets/icons/delete-icon-red.svg';
import IconButton from '../../components/Buttons/IconButton/IconButton';
import ButtonWithoutBG from '../Buttons/ButtonWithoutBG/Button';

interface TableProps {
	content: PlanoProps[];
	editPlan: (id: string) => void;
	deletePlan: (id: string) => void;
	contentMap: { [key: string]: string };
	dynamicDescriptions: string[];
	selectable?: boolean;
	selectAction?: (plan: PlanoProps) => void;
	preSelected?: string;
	viewOnly?: boolean;
}

interface BasePlanoProps {
	idPlanoInSight?: string;
	idPlanosDHM?: string;
	idPlanosRelatorios?: string;
	nomePlano: string;
	preco: number;
	createdAt: string;
}

type PlanoProps = BasePlanoProps & {
	[key: string]: any;
	[key: number]: any;
};

const Table: React.FC<TableProps> = ({
	content,
	editPlan,
	deletePlan,
	contentMap,
	dynamicDescriptions,
	selectable,
	selectAction,
	preSelected,
	viewOnly = false,
}) => {
	const [hoveredColumn, setHoveredColumn] = useState<number | null>(null);
	const [selectedColumn, setSelectedColumn] = useState<number | null>(
		preSelected
			? content.findIndex(
					(plano) =>
						plano.idPlanoInSight === preSelected ||
						plano.idPlanosDHM === preSelected ||
						plano.idPlanosRelatorios === preSelected
				)
			: null
	);

	const handleMouseEnter = (colIndex: number) => setHoveredColumn(colIndex);
	const handleMouseLeave = () => setHoveredColumn(null);

	const handleColumnClick = (colIndex: number, plano: PlanoProps) => {
		if (selectable && selectAction) {
			const newSelectedColumn =
				colIndex === selectedColumn ? null : colIndex;
			setSelectedColumn(newSelectedColumn);
			selectAction(plano);
		}
	};

	const filterPlanName = (name: string) => {
		if (!isNaN(Number(name))) return name;
		return name
			.split(' ')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');
	};

	const filterPlanPrice = (price: number) => price.toFixed(2);

	const formatDate = (dataIso: string) => {
		const date = new Date(dataIso);
		return date.toLocaleDateString('pt-BR', {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
		});
	};

	// Função para agrupar células de nomes repetidos
	const getPlanNameCells = () => {
		const planNameCells: JSX.Element[] = [];
		let start = 0;

		while (start < content.length) {
			const currentPlan = content[start];
			let end = start;

			// Avança até o final do grupo de planos com o mesmo nome
			while (
				end + 1 < content.length &&
				content[end + 1].nomePlano === currentPlan.nomePlano
			) {
				end++;
			}

			const colSpan = end - start + 1; // Calcula quantas colunas esse grupo de planos ocupa

			planNameCells.push(
				<Body.PlanTitle
					key={currentPlan.idPlanoInSight}
					colSpan={colSpan}
					onMouseEnter={() => handleMouseEnter(start)}
					onMouseLeave={handleMouseLeave}
					onClick={() => handleColumnClick(start, currentPlan)}
					isHovered={isColumnHovered(start) && selectable}
					isSelected={isColumnSelected(start)}
				>
					{filterPlanName(currentPlan.nomePlano)}
				</Body.PlanTitle>
			);

			start = end + 1; // Avança para o próximo grupo
		}

		return planNameCells;
	};

	const isColumnHovered = (index: number) => hoveredColumn === index;
	const isColumnSelected = (index: number) => selectedColumn === index;

	return (
		<Body.TableContainer>
			<Body.FixedTable>
				<thead>
					<tr>
						<Body.ThHidden>Escondido</Body.ThHidden>
					</tr>
					<tr key="createdAt">
						<Body.CreatedAtVertical>
							Data de Criação
						</Body.CreatedAtVertical>
					</tr>
				</thead>
				<tbody>
					<tr>
						<Body.PlanTitleVertical>Quotas</Body.PlanTitleVertical>
					</tr>
					{dynamicDescriptions.map((desc, descIndex) => (
						<tr key={descIndex}>
							<Body.PlanDetailsVertical>
								{contentMap[desc]}
							</Body.PlanDetailsVertical>
						</tr>
					))}
					<tr>
						<Body.ThHidden>Escondido</Body.ThHidden>
					</tr>
				</tbody>
			</Body.FixedTable>

			<Body.ScrollableTableWrapper>
				<Body.ScrollableTable>
					<thead>
						<tr>{getPlanNameCells()}</tr>
					</thead>
					<tbody>
						<tr>
							{content.map((plano, index) => (
								<Body.CreatedAt
									key={`${plano.idPlanoInSight}-createdAt`}
									onMouseEnter={() => handleMouseEnter(index)}
									onMouseLeave={handleMouseLeave}
									isHovered={
										isColumnHovered(index) && selectable
									}
									isSelected={isColumnSelected(index)}
								>
									{formatDate(plano.createdAt)}
								</Body.CreatedAt>
							))}
						</tr>
						<tr>
							{content.map((plano, index) => (
								<Body.Price
									key={`${plano.idPlanoInSight}-price`}
									onMouseEnter={() => handleMouseEnter(index)}
									onMouseLeave={handleMouseLeave}
									onClick={() =>
										handleColumnClick(index, plano)
									}
									isHovered={
										isColumnHovered(index) && selectable
									}
									isSelected={isColumnSelected(index)}
								>
									{plano.preco === 0
										? 'Cortesia'
										: `R$ ${filterPlanPrice(plano.preco)}`}
								</Body.Price>
							))}
						</tr>

						{dynamicDescriptions.map((desc, descIndex) => (
							<tr key={descIndex}>
								{content.map((plano, index) => (
									<Body.PlanDetails
										key={`${plano.idPlanoInSight}-${desc}`}
										onMouseEnter={() =>
											handleMouseEnter(index)
										}
										onMouseLeave={handleMouseLeave}
										onClick={() =>
											handleColumnClick(index, plano)
										}
										isHovered={
											isColumnHovered(index) && selectable
										}
										isSelected={isColumnSelected(index)}
									>
										{plano[desc as keyof PlanoProps]}
									</Body.PlanDetails>
								))}
							</tr>
						))}

						<tr>
							{content.map((plano, index) => (
								<Body.PlanOptions
									key={`${plano.idPlanoInSight}-options`}
								>
									<Body.PlanOptionsWrapper
										onMouseEnter={() =>
											handleMouseEnter(index)
										}
										onMouseLeave={handleMouseLeave}
										className={`${isColumnHovered(index) || isColumnSelected(index) ? 'show-icons' : ''}`}
									>
										{isColumnSelected(index) ? (
											<ButtonWithoutBG
												action={() => {}}
												text="Selecionado"
												hoverColor="black"
											/>
										) : selectable ? (
											<ButtonWithoutBG
												action={() =>
													handleColumnClick(
														index,
														plano
													)
												}
												text="Selecionar"
												color={defines.BlueTec}
												hoverColor={defines.BlueTec}
											/>
										) : viewOnly ? (
											<></>
										) : (
											<>
												<IconButton
													action={() => {
														editPlan(
															plano.idPlanoInSight ||
																plano.idPlanosDHM ||
																plano.idPlanosRelatorios ||
																''
														);
													}}
													icon={EditIcon}
												/>
												<IconButton
													action={() => {
														deletePlan(
															plano.idPlanoInSight ||
																plano.idPlanosDHM ||
																plano.idPlanosRelatorios ||
																''
														);
													}}
													icon={TrashIcon}
												/>
											</>
										)}
									</Body.PlanOptionsWrapper>
								</Body.PlanOptions>
							))}
						</tr>
					</tbody>
				</Body.ScrollableTable>
			</Body.ScrollableTableWrapper>
		</Body.TableContainer>
	);
};

export default Table;
