import React, { useEffect, useRef, useState } from 'react';
import Sidebar from '../../components/SideBar/Sidebar';
import * as Body from './Styles';
import SearchIcon from '../../../assets/icons/Search.svg';
import AddIcon from '../../../assets/icons/add-icon.svg';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../components/Loading/Loading';
import Avatar from '../../../assets/images/avatar.png';
import * as Functions from '../../../utils/functions/Functions';
import * as defines from '../../../utils/defines/Defines';
import { State } from '../../redux/store';
import SendInvite from './Modals/SendInvite';
import * as Types from '../../../utils/types/Types';
import ButtonWrapper from '../../components/Buttons/ButtonWrapper/ButtonWrapper';
import ArrowBack from '../../../assets/icons/arrow-back-preto-icon.svg';

type UsuarioProps = {
	idEmpresarial: string;
	idUsuario: string;
	fotoUsuario: string | null;
	ddd: string;
	celular: string;
	nomeCompleto: string;
	email: string;
	cargo: number;
	encarregadoDados: number;
};

const UserList = () => {
	const { state } = useLocation();
	const { filial, company } = state as { filial: Types.FilialInfo, company: Types.EmpresaInfo };

	const acessLevel = useSelector(
		(state: State) => state.acessLevel.acessLevel
	);
	const userType = useSelector((state: State) => state.userType.userType);

	const [hasLength, toggleHasLength] = useState(false);
	const [hasUser, toggleHasUser] = useState(false);
	const [modalCreateUser, toggleCreateUser] = useState(false);
	const [modalUnits, toggleUnits] = useState(false);
	const [modalDelete, toggleDelete] = useState(false);
	const [data, setData] = useState<UsuarioProps[]>([]);
	const [filteredUsers, setFiltered] = useState<UsuarioProps[]>([]);
	const [loading, toggleLoading] = useState(true);

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [nomeCompleto, setNome] = useState('');
	const [email, setEmail] = useState('');
	const [ddd, setDDD] = useState('');
	const [celular, setTelefone] = useState('');
	const [imagemUsuario, setImagem] = useState('');
	const [nivelAcesso, setNivel] = useState(0);
	const [encarregadoDados, setEncarregado] = useState(0);
	const [idUsuario, setId] = useState('');
	const [idEmpresarial, setIdEmpresarial] = useState('');
	const [loadedUserData, setUserData] = useState<UsuarioProps>();
	const inputRef = useRef<any>();

	const getUsers = () => {
		toggleLoading(true);
		axios
			.get(
				defines.apiURL +
					'/api/EmpresarialCRUD?idFilial=' +
					Functions.ReplaceSpecialCharacters(filial.idFilial) +
					'&userTypeRequest=' +
					userType,
				{ withCredentials: true }
			)
			.then((response) => {
				const sorted = Functions.SortResponse(
					response.data.content,
					'nomeCompleto'
				);
				setData(sorted);
				setFiltered(sorted);
				toggleLoading(false);
			})
			.catch((error) => {
				Functions.ResolveErrors(
					error.response.status,
					error.response.data.code,
					error.response.data.message,
					navigate,
					dispatch,
					error.response.data.content
				);
				toggleLoading(false);
			});
	};

	const filterData = (name: string) => {
		if (name.length === 0) {
			setFiltered(data);
			toggleHasLength(false);
			return;
		}
		toggleHasLength(true);
		const filtered = data.filter((item) =>
			item.nomeCompleto.toUpperCase().includes(name.toUpperCase())
		);
		setFiltered(filtered!);
	};

	useEffect(() => {
		getUsers();
	}, [dispatch, navigate]);

	const getCargo = (cargo: number) => {
		switch (cargo) {
			case defines.CargoEmpresarial['Administrador']:
				return 'Administrador';
			case defines.CargoEmpresarial['Padrão']:
				return 'Padrão';
		}
	};

	const handleUserClick = (user: UsuarioProps) => {
		navigate('/usuarios/editar', { state: {user, filial, company} });
	};

	return (
		<Body.Container>
			<Sidebar
				type={userType}
				content={defines.ContentSidebar.MANAGE_COMPANY}
				tituloPagina='Usuários'
				backAction={() => { navigate('/filiais/editar', { state: {filial, company} }); }}
			/>
			<Body.PageTitle style={{ fontSize: '14px', color: 'gray', fontWeight: 'bold', marginTop: '10px' }}>{company.nomeEmpresa}</Body.PageTitle>
			<Body.PageTitle style={{ fontSize: '14px', color: 'gray', fontWeight: 'bold' }}>{filial.nomeFilial}</Body.PageTitle>
			<Body.SubTitle>
				Toque em um usuário para visualizar seus detalhes.
			</Body.SubTitle>
			<Body.Row
				style={{
					width: '100%',
					justifyContent: 'space-between',
					alignItems: 'center',
					marginTop: '20px',
				}}
			>
				<Body.FindContent>
					<Body.Find
						placeholder="Buscar usuário"
						onChange={(e) => filterData(e.target.value)}
						ref={inputRef}
					/>
					<Body.Icon
						src={SearchIcon}
						style={{ cursor: 'text' }}
						onClick={() => inputRef.current?.focus()}
					/>
				</Body.FindContent>
				{acessLevel === defines.CargoTectrol['Suporte'] && (
					<Body.HeaderButton onClick={() => toggleCreateUser(true)}>
						<Body.Icon
							src={AddIcon}
							style={{ position: 'relative', top: 0, left: 0 }}
						/>
					</Body.HeaderButton>
				)}
			</Body.Row>
			<Body.Scroll style={{ marginTop: '20px' }}>
				{filteredUsers.length === 0 ? (
					<Body.Text>Nenhum usuário encontrado</Body.Text>
				) : (
					filteredUsers.map((item, index) => (
						<Body.SelectUser
							onClick={() => handleUserClick(item)}
							key={index.toString()}
						>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									gap: '10px',
								}}
							>
								<Body.Photo src={item.fotoUsuario || Avatar} />
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
									}}
								>
									<Body.Text>{item.nomeCompleto}</Body.Text>
									<Body.NoteText>
										{getCargo(item.cargo)}
									</Body.NoteText>
								</div>
							</div>
						</Body.SelectUser>
					))
				)}
			</Body.Scroll>

			{loading && <Loading />}
			{modalCreateUser ? (
				<SendInvite
					toggle={toggleCreateUser}
					idFilial={filial.idFilial}
					nomeFilial={filial.nomeFilial}
				/>
			) : null}
		</Body.Container>
	);
};

export default UserList;
